import {createContext, useContext} from 'react';
import {LoginContextValue} from './types';
import {isNull} from 'underscore';

export const LoginContext = createContext<LoginContextValue>(null);

export function useLoginContext() {
	const loginContext = useContext(LoginContext);

	if (isNull(loginContext)) {
		throw Error('Login Context has not been Provided!');
	}

	return loginContext;
}
