import {CloseIcon, Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import React, {useCallback} from 'react';
import styles from './styles.module.less';
import {Buttons} from '@esgillc/ui-kit/button';

type WithButtonsModalProps = {
	onClickClose: () => void;
	closeParent: () => void;
};

export function WithButtonsModal({onClickClose, closeParent}: WithButtonsModalProps) {
	const modalRef = useModal();
	const modalClose = useCloseModal(modalRef, onClickClose);

	const onModalClose = useCallback(() => {
		closeParent();
		modalClose();
	}, [closeParent, modalClose]);

	const onClickHelp = useCallback(() => {
		const supprtURL = 'https://support.esgisoftware.com/hc/en-us/articles/209159446-ESGI-Browser-Requirements';

		window.open(supprtURL, '_blank');
	}, []);

	return (
		<Modal modalManagerRef={modalRef}>
			<Modal.Header>
				<Title>Your device resolution does not meet minimum standards for self-assess.</Title>
				<CloseIcon onClick={onModalClose} />
			</Modal.Header>
			<Modal.Body className={styles.buttonsModal}>
				<span>Students must view assessments at a minimum resolution of 1024 x 768.</span>
			</Modal.Body>
			<Modal.Footer>
				<Buttons.Text onClick={onModalClose} className={styles.button}>
					CLOSE
				</Buttons.Text>
				<Buttons.Text onClick={onClickHelp} className={styles.button}>
					LEARN MORE
				</Buttons.Text>
			</Modal.Footer>
		</Modal>
	);
}
