import {AssignmentCenterService, getUser, updateUser} from '@ac/root';
import {StudentAssignmentsInitResponse} from '../types';
import {BehaviorSubject, debounceTime, takeUntil} from 'rxjs';
import {AppSyncService} from '@esgi/aws';

type AssignmentStateChangedMessage = { AssignmentID: string, State: string };

export class StudentAssignmentsService extends AssignmentCenterService {
	public studentAssignmentsData$ =
		new BehaviorSubject<StudentAssignmentsInitResponse | null>(null);
	private readonly controller = 'student-assignments';
	private readonly appSyncService = new AppSyncService();

	constructor() {
		super();
		const {studentID} = getUser();
		this.appSyncService.channel<AssignmentStateChangedMessage>({
			userID: studentID,
			userType: 'student',
			messageType: 'AssignmentStateChanged',
		}).pipe(
			debounceTime(1000),
			takeUntil(this.destroy$),
		).subscribe(message => {
			this.init();
		});
	}

	public init() {
		return this.httpClient.studentApi.get<StudentAssignmentsInitResponse>(
			this.controller,
			'init',
		).subscribe({
			next: (data) => {
				updateUser({studentName: data.studentName});

				this.studentAssignmentsData$.next(data);
			},
		});
	}

	public destroy() {
		super.destroy();
		this.appSyncService.dispose();
	}
}
