import {AssignmentCenterService} from '@ac/root';

export class LogoutService extends AssignmentCenterService {
	private readonly controller = 'login';

	public logout() {
		return this.httpClient.studentApi
			.post(this.controller, 'logout')
			.asObservable();
	}
}
