import {useEffect} from 'react';
import {useService} from '@esgi/core/service';
import {SSOService} from '../service';

export function ByClever() {
	const service = useService(SSOService);

	useEffect(() => {
		service.handleClever();
	}, [service]);

	return null;
}