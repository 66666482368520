import React from 'react';
import styles from './styles.module.less';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {ButtonCircled} from '@ac/kit';

type CloseButtonProps = {
	closed: () => void;
	disabled?: boolean;
};

export function CloseButton({disabled = false, closed}: CloseButtonProps) {
	return (
		<OnHoverTooltip message='Close'>
			<ButtonCircled disabled={disabled} type='button' className={styles.closeButton} onClick={closed}>
				<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M21.5999 9.72942L20.2705 8.39999L14.9999 13.6706L9.72933 8.39999L8.3999 9.72942L13.6705 15L8.3999 20.2706L9.72933 21.6L14.9999 16.3294L20.2705 21.6L21.5999 20.2706L16.3293 15L21.5999 9.72942Z'
						fill='#828282'
					/>
				</svg>
			</ButtonCircled>
		</OnHoverTooltip>
	);
}
