import {useLoginContext} from '../../context';
import {Form, FormElement} from '@esgillc/ui-kit/form';
import {Label, Input} from '@esgillc/ui-kit/form-control';
import {InputControlledError} from '../input-controlled-error';
import {LoginDistrictIdFields, LoginDistrictIdFieldsPatternValidate} from '../../types';
import {InputError} from '../input-error';
import {isNull} from 'underscore';
import {useBehaviorSubject, useStreamEffect} from '@esgillc/ui-kit/utils';
import {SsoRedirectError} from '../sso-redirect-error';
import {SsoButtons} from '../sso-buttons';
import {emptyString} from '../../constants';
import {environment} from '@ac/environments';

type FormDistrictIdProps = {
	formId?: string;
};

export function FormDistrictId({formId}: FormDistrictIdProps) {
	const {loginDistrictIdForm, formClassNames, loginDistrictIdService} = useLoginContext();

	const districtIdFormResponseError = useBehaviorSubject(loginDistrictIdService.districtIdFormResponseError$);

	const isFormDistrictIdValidationEnable = useBehaviorSubject(loginDistrictIdService.isFormDistrictIdValidationEnable$);

	const ssoIsActive = environment.country !== 'ca';

	useStreamEffect(loginDistrictIdForm.onChanged, ({reason}) => {
		if (reason === 'value' && !isNull(districtIdFormResponseError)) {
			loginDistrictIdService.clearDistrictIdFormResponseError();
		}
	});

	useStreamEffect(loginDistrictIdService.districtIdFormResponseError$, (formError) => {
		if (!isNull(formError)) {
			loginDistrictIdForm.controls[LoginDistrictIdFields.DistrictId].value = emptyString;
		}
	});

	return (
		<Form
			controller={loginDistrictIdForm}
			className={formClassNames.form}
			id={formId}
			autoCapitalize='off'
			autoCorrect='off'
		>
			<SsoRedirectError />
			<FormElement
				control={loginDistrictIdForm.controls[LoginDistrictIdFields.DistrictId]}
				className={formClassNames.formElement}
				disableValidateOnChange={!isFormDistrictIdValidationEnable}
				disableValidateOnBlur={!isFormDistrictIdValidationEnable}
			>
				<Label className={formClassNames.label}>Enter Your District ID</Label>
				<Input
					classNameSuccess={formClassNames.success}
					classNameError={formClassNames.error}
					id={LoginDistrictIdFields.DistrictId}
					name={LoginDistrictIdFields.DistrictId}
				/>

				<InputControlledError showOnError='required'>District ID is required</InputControlledError>

				<InputControlledError showOnError={LoginDistrictIdFieldsPatternValidate.OnlyNumbers}>
					District ID should contain only numbers
				</InputControlledError>
				<InputError>{districtIdFormResponseError}</InputError>
				{ssoIsActive && <SsoButtons />}
			</FormElement>
		</Form>
	);
}
