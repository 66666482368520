import styles from './login.module.less';
import {useCallback, useMemo} from 'react';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {LoginContextValue} from './types';
import {LoginContext} from './context';
import {LoginUserCredentialsService} from './services/login-user-credentials-service';
import {LoginDistrictIdService} from './services/login-district-id-service';
import {FormDistrictId} from './components/form-district-id';
import {FormUserCredentials} from './components/form-user-credentials';
import {useDistrictIdForm} from './hooks/use-district-id-form';
import {useUserCredentialsForm} from './hooks/use-user-credentials-form';
import {ButtonCircled, IconArrowRight, LayoutPage} from '@ac/kit';
import {useService} from '@esgi/core/service';
import {ImagePuzzle} from './components/image-puzzle';

export function Login() {
	const loginDistrictIdService = useService(LoginDistrictIdService);
	const loginUserCredentialsService = useService(LoginUserCredentialsService);

	const {loginDistrictIdForm, loginDistrictIdFormId, onDistrictIdFormSubmit} =
		useDistrictIdForm({
			loginDistrictIdService,
		});

	const {
		form: loginForm,
		formID: loginFormID,
		onFormSubmit: onLoginFormSubmit,
	} = useUserCredentialsForm(loginUserCredentialsService);

	const districtIdValidValue = useBehaviorSubject(
		loginDistrictIdService.districtIdValidValue$
	);
	const isFormDistrictIdSubmitting = useBehaviorSubject(
		loginDistrictIdService.isFormDistrictIdSubmitting$
	);
	const isFormUserCredentialsSubmitting = useBehaviorSubject(
		loginUserCredentialsService.isFormSubmitting$
	);

	const isLoginFormSubmitting =
		isFormDistrictIdSubmitting || isFormUserCredentialsSubmitting;

	const submit = useCallback(() => {
		if (!districtIdValidValue) {
			onDistrictIdFormSubmit();

			return;
		}

		onLoginFormSubmit(Number(districtIdValidValue));
	}, [districtIdValidValue, onDistrictIdFormSubmit, onLoginFormSubmit]);

	const loginValue = useMemo<LoginContextValue>(
		() => ({
			loginDistrictIdForm,
			loginUserCredentialsForm: loginForm,
			formClassNames: {
				form: styles.form,
				formElement: styles.formElement,
				label: styles.label,
				success: styles.success,
				error: styles.error,
			},
			loginDistrictIdService,
			loginUserCredentialsService,
		}),
		[
			loginDistrictIdForm,
			loginForm,
			loginDistrictIdService,
			loginUserCredentialsService,
		]
	);

	return (
		<LayoutPage pageTitle='Assignment Center' mainClassName={styles.content}>
			<div className={styles.container}>
				<div className={styles.formContainer}>
					<LoginContext.Provider value={loginValue}>
						{!districtIdValidValue ? (
							<FormDistrictId formId={loginDistrictIdFormId} />
						) : (
							<FormUserCredentials formId={loginFormID} />
						)}
					</LoginContext.Provider>
				</div>
			</div>

			<ButtonCircled
				className={styles.buttonLogin}
				color='success'
				onClick={submit}
				disabled={isLoginFormSubmitting}
				type='submit'
				form={!districtIdValidValue ? loginDistrictIdFormId : loginFormID}
			>
				<IconArrowRight />
			</ButtonCircled>

			<ImagePuzzle color='orrange' className={styles.puzzleOrrange} />
			<ImagePuzzle color='green' className={styles.puzzleGreen} />
			<ImagePuzzle color='white' className={styles.puzzleWhite} />
			<ImagePuzzle color='yellow' className={styles.puzzleYellow} />
		</LayoutPage>
	);
}
