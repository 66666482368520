/* eslint-disable @typescript-eslint/member-ordering */
import {BehaviorSubject, Subject, map, of, tap} from 'rxjs';
import {
	AnswerModel,
	EndTestRequest,
	FullTestModel,
	Question,
	StudentsTestAnswer,
	Template,
	Test,
	StartTestSessionResponse,
} from '../types';
import {last} from 'underscore';
import {AssignmentCenterService} from '@ac/root';

export class StudentAssignmentTestingService extends AssignmentCenterService {
	private readonly controller = 'testing';

	private assignmentID: number;
	private userID: number;
	public awsBucket: string;

	public selectedTests$ = new BehaviorSubject<Test[] | null>(null);
	public currentTest$ = new BehaviorSubject<Test | null>(null);
	public currentQuestion$ = new BehaviorSubject<Question | null>(null);
	public finishTest$ = new Subject<boolean>();

	private studentsAnswers: StudentsTestAnswer[] = [];

	constructor({awsBucket, assignmentID, userID}: {awsBucket: string; assignmentID: number; userID: number}) {
		super();

		this.awsBucket = awsBucket;
		this.assignmentID = assignmentID;
		this.userID = userID;
	}

	public startTestSession$() {
		return this.httpClient.studentApi
			.post<StartTestSessionResponse>(this.controller, 'start-test-session', {
				assignmentID: this.assignmentID,
				userID: this.userID,
			})
			.asObservable()
			.pipe(
				map(({tests, progress}) => {
					const mappedTests: Test[] = this.mapTests(tests);

					const notCompletedTest = progress.find(({isCompleted}) => !isCompleted);

					if (notCompletedTest) {
						const {testID, resumeModel} = notCompletedTest;

						const currentTest = mappedTests.find(({id}) => id === testID);

						if (currentTest) {
							this.currentTest$.next(currentTest);
						}

						if (resumeModel.length) {
							const answers = resumeModel.map<AnswerModel>(({answers, questionID, duration}) => ({
								duration,
								questionID,
								optionIDs: answers,
							}));

							this.studentsAnswers = [
								{
									testID,
									answers,
								},
							];
						}
					}

					this.selectedTests$.next(mappedTests);
				}),
			);
	}

	private parseQuestionTemplate(template: string) {
		const parsedTemplate: Template = JSON.parse(template);

		return parsedTemplate;
	}

	private mapTests(tests: FullTestModel[]) {
		const mappedTests: Test[] = tests.map(({id, name, contentAreaID, contentAreaName, questions}) => ({
			id,
			name,
			contentAreaID,
			contentAreaName,
			questionsCount: questions.length,
			questions: questions.map(({id, selfAssessmentQuestionTemplate, template}) => ({
				id,
				templateType: selfAssessmentQuestionTemplate,
				...this.parseQuestionTemplate(template),
			})),
		}));

		return mappedTests;
	}

	public runTest$() {
		const currentTestValue = this.currentTest$.value;
		const questionIDs = currentTestValue?.questions.map(({id}) => id);

		return this.httpClient.studentApi
			.post<void>(this.controller, 'run-test', {
				testID: this.currentTest$.value?.id,
				questionIDs,
				assignmentID: this.assignmentID,
				userID: this.userID,
			})
			.asObservable();
	}

	public get isLastQuestion(): boolean {
		const currentTestValue = this.currentTest$.value;
		const currentQuestionValue = this.currentQuestion$.value;

		if (!currentTestValue || !currentQuestionValue) {
			return false;
		}

		const {questions} = currentTestValue;
		const {id} = currentQuestionValue;

		return questions.findIndex(({id: questionId}) => questionId === id) === 0;
	}

	public setFirstQuestion(test: Test | null) {
		if (!test) {
			return;
		}

		const answerTest = this.studentsAnswers.find(({testID}) => testID === test.id);

		if (answerTest) {
			const lastAnswer = last(answerTest.answers);

			if (lastAnswer?.questionID) {
				const index = test.questions.findIndex((t) => t.id === lastAnswer.questionID);

				if (index > -1) {
					const nextTestQuestion = test.questions[index + 1];

					this.currentQuestion$.next(nextTestQuestion ?? null);
				}

				return;
			}
		}

		this.currentQuestion$.next(test.questions[0] ?? null);
	}

	public previousQuestion(): number[] {
		const question = this.currentQuestion$.value;
		const test = this.currentTest$.value;

		if (!question || !test) {
			return [];
		}

		const index = test.questions.findIndex(({id: testQuestionId}) => testQuestionId === question.id);
		const previousQuestion = test.questions[index - 1];

		if (index === 0 || !previousQuestion) {
			return [];
		}

		const studentsAnswers = this.studentsAnswers.find((studentAnswer) => studentAnswer.testID === test.id);

		if (!studentsAnswers) {
			return [];
		}

		const answer = studentsAnswers.answers.find(({questionID}) => questionID === previousQuestion.id);

		if (!answer) {
			return [];
		}

		this.currentQuestion$.next(previousQuestion);

		return answer.optionIDs;
	}

	public nextQuestion(optionIDs: number[], duration: number) {
		const question = this.currentQuestion$.value;
		const test = this.currentTest$.value;

		if (!test || !question) {
			return of([]);
		}

		const index = test.questions.findIndex(({id: testQuestionId}) => testQuestionId === question.id);

		const durationAnswerTime = this.setStudentAnswer(question.id, optionIDs, duration);

		const observable = this.httpClient.studentApi
			.post<void>(this.controller, 'answer-question', {
				testID: test.id,
				questionID: question.id,
				optionIDs,
				duration: durationAnswerTime ?? duration,
				assignmentID: this.assignmentID,
				userID: this.userID,
			})
			.asObservable();

		return observable.pipe(
			map(() => {
				const isLastQuestion = index === test.questions.length - 1;

				if (isLastQuestion && this.selectedTests$.value) {
					const {id: lastTestID} = this.selectedTests$.value.at(-1)!;
					const isLastTest = test.id === lastTestID;

					this.finishTest$.next(isLastTest);

					return [];
				}

				const nextQuestion = test.questions[index + 1];
				const testAnswers = this.studentsAnswers.find(({testID}) => testID === test.id);

				if (!testAnswers || !nextQuestion) {
					return [];
				}

				this.currentQuestion$.next(nextQuestion);

				const answer = testAnswers.answers.find(({questionID}) => questionID === nextQuestion.id);

				if (!answer) {
					return [];
				}

				return answer.optionIDs;
			}),
		);
	}

	private setStudentAnswer(questionID: number, optionIDs: number[], duration: number) {
		const currentTest = this.currentTest$.value;

		if (!currentTest) {
			return;
		}

		const currentTestID = currentTest.id;
		const value = this.studentsAnswers.find((studentAnswer) => studentAnswer?.testID === currentTestID);

		if (!value) {
			const answers: AnswerModel[] = [{questionID, optionIDs, duration}];
			this.studentsAnswers = [...this.studentsAnswers, {testID: currentTestID, answers}];

			return;
		}

		const answerValue = value.answers.find((answer) => answer.questionID === questionID);

		if (!answerValue) {
			const answerModel: AnswerModel = {questionID, optionIDs, duration};
			value.answers = [...value.answers, answerModel];

			return;
		}

		const durationFullTime = answerValue.duration + duration;

		answerValue.duration = durationFullTime;
		answerValue.optionIDs = optionIDs;

		return durationFullTime;
	}

	public testFinish$() {
		const test = this.currentTest$.value;

		if (!test) {
			throw new Error('Current test is null.');
		}

		const studentsAnswers = this.studentsAnswers.find(({testID}) => testID === test.id);

		if (!studentsAnswers) {
			throw new Error('Can not find student answers.');
		}

		const data: EndTestRequest = {
			testID: test.id,
			answers: studentsAnswers.answers,
			assignmentID: this.assignmentID,
			userID: this.userID,
		};

		return this.httpClient.studentApi
			.post<void>(this.controller, 'end-test', data)
			.pipe(
				tap(() => {
					const selectedTests = this.selectedTests$.value;

					if (!selectedTests) {
						return;
					}

					const testIndex = selectedTests.findIndex(({id}) => id === data.testID);

					if (testIndex < selectedTests.length - 1) {
						const nextTest = selectedTests[testIndex + 1]!;

						this.currentTest$.next(nextTest);
					}
				}),
			)
			.asObservable();
	}

	public endTestSession$() {
		return this.httpClient.studentApi
			.post<void>(this.controller, 'end-test-session', {
				assignmentID: this.assignmentID,
				userID: this.userID,
			})
			.asObservable();
	}
}
