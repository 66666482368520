import styles from './progress-bar.module.less';
import {join} from '@esgillc/ui-kit/utils';

type ProgressBarProps = {
	className?: string | undefined;
	// value: float; (0.33 = 33%)
	value?: number;
};

export function ProgressBar({className, value = 0}: ProgressBarProps) {
	return (
		<div className={join(styles.progress, className)}>
			<span
				style={{
					width: `${value * 100}%`,
				}}
				className={join(styles.value, value > 0 && styles.withBorderRight)}
			/>
		</div>
	);
}
