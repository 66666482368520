/**
 * Icons are imported as React Components from .svg files
 *
 * @see https://react-svgr.com/docs/webpack/
 */

import {SVGProps} from 'react';
import Play from './svgs/play-icon.svg';
import Star from './svgs/star-icon.svg';
import ArrowRight from './svgs/arrow-right-icon.svg';
import Logout from './svgs/logout.svg';

type SVGComponent = (props: SVGProps<SVGSVGElement>) => JSX.Element;

export const IconPlay: SVGComponent = Play;
export const IconStar: SVGComponent = Star;
export const IconArrowRight: SVGComponent = ArrowRight;
export const IconLogout: SVGComponent = Logout;
