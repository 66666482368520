import {Subject} from 'rxjs';

export type UserInfo = {
	districtID: number;
	primaryTeacherID: number;
	schoolID: number;
	selectedGlobalSchoolYearID: number;
	studentID: number;
	studentName?: string;
}

export const storageKey = 'ac-currentUser';

export const onUserChangedEmitter = new Subject<UserInfo>();

export function getUser() {
	const userStr = localStorage.getItem(storageKey);
	if(userStr) {
		return JSON.parse(userStr) as UserInfo;
	}
	return;
}

export function setUser(context: UserInfo) {
	localStorage.setItem(storageKey, JSON.stringify(context));
	onUserChangedEmitter.next({...context});
}

export function updateUser(value: Partial<UserInfo> ) {
	setUser({...getUser(), ...value});
}
