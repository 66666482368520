import {Text} from '../text';
import {ProgressBar} from '../progress-bar';
import {useCallback, useMemo} from 'react';
import {ButtonCircled, IconPlay} from '@ac/kit';
import {TestsProgress} from '../../types';
import {useConvertedPublishDate} from './hooks/use-converted-publish-date';
import {useGetTestsInfo} from './hooks/use-get-tests-info';
import styles from './styles.module.less';

export type SelfAssessmentData = {
	assignmentID: number;
	userID: number;
};

type AssignmentCardProps = {
	publishDate: string | Date;
	assignmentName: string;
	onSelfAssessmentStartClick: (selfAssessmentData: SelfAssessmentData) => void;
	selfAssessmentProps: {
		id: number;
		testsProgress: TestsProgress[];
		userID: number;
	};
	selfAssessTestingOpened: boolean;
	createdAssignmentUserName: string;
};

export function AssignmentCard({
	assignmentName,
	publishDate,
	selfAssessmentProps: {testsProgress, id: assignmentID, userID},
	selfAssessTestingOpened,
	onSelfAssessmentStartClick,
	createdAssignmentUserName,
}: AssignmentCardProps) {
	const convertedPublishDate = useConvertedPublishDate(publishDate);
	const {allTestsCount, passedTestsCount} = useGetTestsInfo(testsProgress);

	const progressBarValue = useMemo(() => passedTestsCount / allTestsCount, [passedTestsCount, allTestsCount]);

	const onStartClick = useCallback(() => {
		onSelfAssessmentStartClick({assignmentID, userID});
	}, [onSelfAssessmentStartClick, assignmentID, userID]);

	return (
		<div className={styles.cardContainer}>
			<div className={styles.assignmentTitleInfo}>
				<Text as='p' color='white' size='16px' weight='500'>
					{createdAssignmentUserName}
				</Text>
				<Text as='p' color='white' size='16px' weight='500'>
					{convertedPublishDate}
				</Text>
			</div>
			<div className={styles.cardContent}>
				<Text as='h3' color='primary' size='16px' weight='500' maxLines='2'>
					{assignmentName}
				</Text>
				<div className={styles.testInfo}>
					<div className={styles.testProgress}>
						<Text as='p' color='secondary' size='14px' weight='400'>
							Tests: {allTestsCount}
						</Text>
						<div className={styles.progressBarInfo}>
							<ProgressBar value={progressBarValue} className={styles.progressBar} />
							<Text as='p' color='black' size='14px' weight='400'>
								{passedTestsCount}/{allTestsCount}
							</Text>
						</div>
					</div>
					<ButtonCircled
						className={styles.buttonPlay}
						color='success'
						onClick={onStartClick}
						disabled={selfAssessTestingOpened}
					>
						<IconPlay />
					</ButtonCircled>
				</div>
			</div>
		</div>
	);
}
