import {isNull} from 'underscore';
import {FlexBox} from '@esgillc/ui-kit/layout';
import {Form, FormElement} from '@esgillc/ui-kit/form';
import {Label, Input} from '@esgillc/ui-kit/form-control';
import {useBehaviorSubject, useStreamEffect} from '@esgillc/ui-kit/utils';
import {usernameMinLength, passwordMinLength, emptyString} from '../../constants';
import {LoginUserCredentialsFields} from '../../types';
import {useLoginContext} from '../../context';
import {InputControlledError} from '../input-controlled-error';
import {InputError} from '../input-error';

type FormUserCredentialsProps = {
	formId?: string;
};

export function FormUserCredentials({formId}: FormUserCredentialsProps) {
	const {loginUserCredentialsForm, formClassNames, loginUserCredentialsService} = useLoginContext();

	const userCredentialsFormResponseError = useBehaviorSubject(loginUserCredentialsService.responseError$);

	const isFormUserCredentialsValidationEnable = useBehaviorSubject(loginUserCredentialsService.isValidationEnable$);

	useStreamEffect(loginUserCredentialsForm.onChanged, ({reason}) => {
		if (reason === 'value' && !isNull(userCredentialsFormResponseError)) {
			loginUserCredentialsService.clearResponseError();
		}
	});

	useStreamEffect(loginUserCredentialsService.responseError$, (formError) => {
		if (!isNull(formError)) {
			const formControlFields = loginUserCredentialsForm.controls;

			Object.keys(formControlFields).forEach((key) => {
				const keyTyped = key as LoginUserCredentialsFields;

				formControlFields[keyTyped].value = emptyString;
			});
		}
	});

	return (
		<FlexBox direction='column'>
			<Form
				controller={loginUserCredentialsForm}
				className={formClassNames.form}
				id={formId}
				autoCapitalize='off'
				autoCorrect='off'
			>
				<FormElement
					control={loginUserCredentialsForm.controls[LoginUserCredentialsFields.UserName]}
					className={formClassNames.formElement}
					disableValidateOnChange={!isFormUserCredentialsValidationEnable}
					disableValidateOnBlur={!isFormUserCredentialsValidationEnable}
				>
					<Label className={formClassNames.label}>Enter Your Username</Label>
					<Input
						classNameSuccess={formClassNames.success}
						classNameError={formClassNames.error}
						id={LoginUserCredentialsFields.UserName}
						name={LoginUserCredentialsFields.UserName}
					/>

					<InputControlledError showOnError='required'>Username is required</InputControlledError>
					<InputControlledError showOnError='length-min'>
						{`Username must be at least ${usernameMinLength} digits`}
					</InputControlledError>
				</FormElement>
				<FormElement
					control={loginUserCredentialsForm.controls[LoginUserCredentialsFields.Password]}
					className={formClassNames.formElement}
					disableValidateOnChange={!isFormUserCredentialsValidationEnable}
					disableValidateOnBlur={!isFormUserCredentialsValidationEnable}
				>
					<Label className={formClassNames.label}>Enter Your Password</Label>
					<Input
						classNameSuccess={formClassNames.success}
						classNameError={formClassNames.error}
						id={LoginUserCredentialsFields.Password}
						type='password'
						name={LoginUserCredentialsFields.Password}
					/>

					<InputControlledError showOnError='required'>Password is required</InputControlledError>
					<InputControlledError showOnError='length-min'>
						{`Password must be at least ${passwordMinLength} digits`}
					</InputControlledError>
				</FormElement>

				<InputError align='center'>{userCredentialsFormResponseError}</InputError>
			</Form>
		</FlexBox>
	);
}
