// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

import {Environment} from './types';

export const environment: Environment = {
	studentApiURL: null,
	ssoApiURL: null,
	url: null,
	type: null,
	country: null,
};
