import {BehaviorSubject} from 'rxjs';
import {HttpStatusCode, isAxiosError} from 'axios';
import {AssignmentCenterService} from '@ac/root';
import {ValidateDistrictResponse} from '../types';

export class LoginDistrictIdService extends AssignmentCenterService {
	public districtIdValidValue$ = new BehaviorSubject<string | null>(null);
	public districtIdFormResponseError$ = new BehaviorSubject<string | null>(null);

	public isFormDistrictIdSubmitting$ = new BehaviorSubject(false);
	public isFormDistrictIdValidationEnable$ = new BehaviorSubject(false);

	private readonly controller = 'login';

	private setIsFormDistrictIdSubmitting(value: boolean) {
		this.isFormDistrictIdSubmitting$.next(value);
	}

	public clearDistrictIdFormResponseError() {
		this.districtIdFormResponseError$.next(null);
	}

	public setIsFormDistrictIdValidationEnable(value: boolean) {
		this.isFormDistrictIdValidationEnable$.next(value);
	}

	public get isFormDistrictIdValidationEnable() {
		return this.isFormDistrictIdValidationEnable$.value;
	}

	public checkDistrictId(districtId: string) {
		this.setIsFormDistrictIdSubmitting(true);

		this.httpClient.studentApi.get<ValidateDistrictResponse>(
			this.controller,
			'validate-district',
			{districtId}
		).subscribe({
			next: ({isSuccess}) => {
				if (isSuccess) {
					this.districtIdValidValue$.next(districtId);
				}

				this.setIsFormDistrictIdSubmitting(false);
			},
			error: (error) => {
				if (isAxiosError(error)) {
					const statusCode = error?.response?.status;
					const errorMessageCode: string = error?.response?.data?.message;

					const isInvalidResponse = errorMessageCode.includes('InvalidRequest');

					if (
						statusCode === HttpStatusCode.InternalServerError &&
						isInvalidResponse
					) {
						this.districtIdFormResponseError$.next(
							`The District ID (${districtId}) you entered is incorrect. Please refer to your student card or ask your teacher for the District ID and try again.`
						);
					}
				}
				this.setIsFormDistrictIdSubmitting(false);
			},
		});
	}
}
