import moment from 'moment';
import {useMemo} from 'react';

export function useConvertedPublishDate(date: string | Date) {
	return useMemo(() => {
		const today = moment();
		const checkedDate = moment(date);

		const daysDifference = today.diff(checkedDate, 'days');

		const dateFormat = daysDifference === 0 ? '[Today]' : 'dddd[,] MMMM DD';

		return checkedDate.format(dateFormat);
	}, [date]);
}
