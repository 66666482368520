import {join} from '@esgillc/ui-kit/utils';
import styles from './styles.module.less';

type ImagePuzzleProps = {
	color: 'orrange' | 'white' | 'green' | 'yellow';
	className?: string;
};

export function ImagePuzzle({color, className}: ImagePuzzleProps) {
	return (
		<img
			src={`https://esgiwebfiles.s3.amazonaws.com/images/assignment-center/puzzle-${color}.png`}
			className={join(styles.puzzle, className)}
			alt={`puzzle-${color}`}
		/>
	);
}
