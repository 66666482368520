import {PropsWithChildren} from 'react';
import styles from './styles.module.less';
import {join} from '@esgillc/ui-kit/utils';

type InputErrorProps = PropsWithChildren<{
	align?: 'left' | 'center' | 'right';
}>;

export function InputError({children, align = 'left'}: InputErrorProps) {
	return <span className={join(styles.errorMessage, styles[`align-${align}`])}>{children}</span>;
}
