import {Test} from '../../../../types';
import {Card} from '../../../card';
import {ContentAreaIcon} from '../../../content-area-icon';
import {StartButton} from '../../../start-button';
import styles from './styles.module.less';

type TetsCardProps = {
	test: Test;
	index: number;
	selected: boolean;
	startTestClicked: () => void;
};

export function TetsCard({index, test, selected, startTestClicked}: TetsCardProps) {
	return (
		<Card selected={selected} titleNumber={index + 1}>
			<Card.Header titleNumber={index + 1} infoText={test.name}>
				<span className={styles.cardHeader}>{test.contentAreaName}</span>
			</Card.Header>
			<Card.Body>
				<ContentAreaIcon contentAreaID={test.contentAreaID} />
				{selected && <StartButton onClick={startTestClicked} />}
			</Card.Body>
		</Card>
	);
}
