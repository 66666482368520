import {LoadScriptFailedEvent} from '@ac/utils';
import {dispatchAppEvent} from '@esgillc/events';
import React, {ComponentType, FunctionComponent, lazy, Suspense} from 'react';

type ExtraComponentType<P> = ComponentType<P> | ((props: P) => JSX.Element);

/**
 * Return asynchronous React Component loader.
 * @param factory import statement wrapped with arrow function.
 * @example
 * const lazy = lazyComponent(() => import('./a.tsx'));
 * ...
 * render() {
 *     return <Suspense fallback={...}>
 *              <lazy/>
 *         </Suspense>
 * };
 * ...
 * @return LazyExoticComponent
 * @tutorial
 */
export function lazyComponent<P>(
	factory: () => Promise<{ default: ExtraComponentType<P> }>
): FunctionComponent<P> {
	const Component = lazy(async () => {
		let mod;
		while (!mod) {
			try {
				mod = await factory();
			} catch (err) {
				const chunkFailMessage = /Loading chunk [\d]+ failed/;

				if (
					typeof err === 'object' &&
					err !== null &&
					'message' in err &&
					typeof err.message === 'string'
				) {
					if (err && chunkFailMessage.test(err.message)) {
						dispatchAppEvent(
							LoadScriptFailedEvent,
							new LoadScriptFailedEvent()
						);
						return {default: EmptyComponent};
					} else {
						throw err;
					}
				}
			}
		}
		return mod;
	});
	return (props: P) => (
		<Suspense fallback={<></>}>
			<Component {...props} />
		</Suspense>
	);
}

class EmptyComponent extends React.PureComponent {
	public render() {
		return <></>;
	}
}
