import React, {useCallback, useState} from 'react';
import {StudentAssignmentTestingService} from '../../services/student-assignment-testing-service';
import {Modal, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import styles from './styles.module.less';
import {CloseButton} from '../close-button';
import {TetsCard} from './components/test-card';
import {TestModal} from './components/test-modal';
import {filter, tap} from 'rxjs';
import {LockScreen} from './components/lock-screen';
import {useStream, useStreamEffect} from '@esgillc/ui-kit/utils';
import {NameBar} from '@ac/kit';

type StudentViewModalProps = {
	dataService: StudentAssignmentTestingService;
	closed: () => void;
	studentName: string;
	testSessionEnded: () => void;
};

export function StudentViewModal({closed, dataService, studentName, testSessionEnded}: StudentViewModalProps) {
	const [testModalOpened, setTestModalOpened] = useState(false);

	const modalRef = useModal();
	const modalClose = useCloseModal(modalRef, closed);
	const modalTestSessionClose = useCloseModal(modalRef, testSessionEnded);

	const selectedTests = useStream(dataService.selectedTests$);
	const currentTest = useStream(dataService.currentTest$.pipe(tap(dataService.setFirstQuestion.bind(dataService))));

	useStreamEffect(dataService.finishTest$.pipe(filter((isLatestTest) => isLatestTest)), modalTestSessionClose);

	useStreamEffect(dataService.currentTest$, dataService.setFirstQuestion.bind(dataService));

	const startTestClicked = useCallback(() => {
		dataService.runTest$().subscribe(() => {
			setTestModalOpened(true);
		});
	}, [dataService]);

	const onTestModalClose = useCallback(() => {
		setTestModalOpened(false);
	}, []);

	return (
		<>
			<Modal containerClassName={styles.modalWrapper} className={styles.modal} modalManagerRef={modalRef}>
				<Modal.Header className={styles.launcherHeader}>
					<NameBar title={studentName} className={styles.starredNameContainer} />
					<CloseButton closed={modalClose} />
				</Modal.Header>
				<Modal.Body className={styles.body}>
					<div className={styles.launcherBody}>
						<div className={styles.cardsContainer}>
							{selectedTests?.map((test, index) => (
								<TetsCard
									index={index}
									test={test}
									selected={currentTest?.id === test.id}
									startTestClicked={startTestClicked}
									key={test.id}
								/>
							))}
						</div>
					</div>
				</Modal.Body>
			</Modal>

			{testModalOpened && <TestModal dataService={dataService} closed={onTestModalClose} />}

			<LockScreen closeParent={modalClose} />
		</>
	);
}
