import {MonoTypeOperatorFunction, Observable, Subscription} from 'rxjs';
import {tryCall} from '@esgillc/ui-kit/utils';


type Config = {
	count: number,
	resetOnSuccess?: boolean,
	filter?: (error: any, attempts: number) => boolean,
	logger?: (message?: string | object, error?: string | object) => any,
	debounce?: (attempt: number) => number;
}

function unwrapConfig(configOrCount: number | Config): Config {
	const defaultConfig = {
		count: 0,
		resetOnSuccess: false,
		filter: () => true,
		logger: (message, error) => {
			if (message) {
				console.warn(message);
			}

			if (error) {
				console.error(error);
			}
		},
		debounce: attempt => 0,
	} as Config;

	if (typeof configOrCount === 'object') {
		return {
			...defaultConfig,
			...configOrCount,
		};
	}
	if (typeof configOrCount === 'number') {
		return {
			...defaultConfig,
			count: configOrCount,
		};
	}
	return defaultConfig;
}

export function retry<T>(configOrCount: number | Config): MonoTypeOperatorFunction<T> {
	return (source: Observable<T>) => {
		return new Observable<T>((subscriber) => {
			const config = unwrapConfig(configOrCount);
			const innerSubRef: { sub: Subscription | null } = {sub: null};
			let soFar = -1;
			const resubscribe = (error?: any) => {
				soFar++;
				if (config.count < soFar) {
					if (soFar > 0) {
						config.logger(undefined, error);
						throw new Error(`Failed after ${soFar - 1} attempts`);
					} else {
						throw error;
					}
				}
				if (error) {
					config.logger(undefined, error);
				}

				if (innerSubRef.sub && !innerSubRef.sub.closed) {
					innerSubRef.sub.unsubscribe();
				}

				const subscribe = () => {
					if (soFar > 0) {
						tryCall(() => config.logger(`Attempt ${soFar}`));
					}
					innerSubRef.sub = source.subscribe({
						next: (value: T) => {
							if(config.resetOnSuccess) {
								soFar = -1;
							}
							subscriber.next(value);
						},
						error: (error) => {
							const pass = config.filter(error, soFar);
							if (pass) {
								resubscribe(error);
							} else {
								subscriber.error(error);
							}
						},
						complete: () => {
							subscriber.complete();
						},
					});
				};

				const delay = config.debounce(soFar);

				if (delay && delay > 0) {
					setTimeout(subscribe, delay);
				} else {
					subscribe();
				}
			};

			resubscribe();

			return () => innerSubRef.sub?.unsubscribe();
		});
	};
}