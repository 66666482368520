import {join} from '@esgillc/ui-kit/utils';
import {Text} from '../text';
import styles from './styles.module.less';

type InfoTextProps = {
	mainText: string;
	additionText: string;
	className?: string;
};

export function InfoText({additionText, mainText, className}: InfoTextProps) {
	return (
		<Text as='h2' color='secondary' size='16px' weight='400' className={join(styles.infoText, className)}>
			{mainText}:{' '}
			<Text as='span' color='currentColor' weight='500' size='16px'>
				{additionText}
			</Text>
		</Text>
	);
}
