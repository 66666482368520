import {UserInfo, onUserChangedEmitter, getUser} from '@ac/root';
import LogRocket from 'logrocket';


function identifyLogRocketUser({studentID, studentName}: UserInfo): void {
	if (studentID) {
		LogRocket.identify(studentID.toString(), {
			name: studentName,
			userID: studentID,
		});
	}
}

export function initLogRocket () {
	let initializedUser = getUser();

	if (location.host === 'app.esgisoftware.com') {
		LogRocket.init('qqmiuo/esgi_prod');
		if (initializedUser) {
			identifyLogRocketUser(initializedUser);
		}

	}

	onUserChangedEmitter.asObservable().subscribe((user) => {
		if (initializedUser?.studentID && user?.studentID !== initializedUser?.studentID) {
			LogRocket.startNewSession();
		}

		initializedUser = getUser();
		identifyLogRocketUser(initializedUser);
	});
}
