import {Environment, EnvironmentValue} from '../types';

export const initEnvironmenValues = (
	environment: Environment,
	{studentApiURL, ssoApiURL, url, type, country}: EnvironmentValue
) => {
	environment.studentApiURL = studentApiURL;
	environment.ssoApiURL = ssoApiURL;
	environment.type = type;
	environment.url = url;
	environment.country = country;
};
