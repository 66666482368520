import {routePaths} from '@ac/router/routes';
import {AssignmentCenterService, setUser} from '@ac/root';
import {SSOLoginResponse, SSORedirectError, SSOUserData} from './types';

export class SSOService extends AssignmentCenterService {
	private readonly token: string;
	private readonly redirectError: SSORedirectError;
	private readonly controller = 'login';

	constructor() {
		super();
		const urlSearchParams = new URLSearchParams(window.location.search);
		this.token = urlSearchParams.get('token');
		this.redirectError = urlSearchParams.get('error') as SSORedirectError;
	}

	public handleClasslink() {
		if (this.redirectError) {
			this.handleRedirectWithError(this.redirectError);
			return;
		}

		this.httpClient.studentApi.get<SSOLoginResponse>(
			this.controller,
			'by-class-link',
			{token: this.token}
		).subscribe({
			next: ({isSuccess, value}) => {
				if (isSuccess) {
					this.authenticateUser(value);
					return;
				}

				this.handleRedirectWithError(SSORedirectError.SomethingWentWrong);
			},
			error: () => {
				this.handleRedirectWithError(SSORedirectError.SomethingWentWrong);
			},
		});
	}

	public handleClever() {
		if (this.redirectError) {
			this.handleRedirectWithError(this.redirectError);
			return;
		}

		this.httpClient.studentApi.get<SSOLoginResponse>(
			this.controller,
			'by-clever',
			{token: this.token}
		).subscribe({
			next: ({isSuccess, value}) => {
				if (isSuccess) {
					this.authenticateUser(value);
					return;
				}

				this.handleRedirectWithError(SSORedirectError.SomethingWentWrong);
			},
			error: () => {
				this.handleRedirectWithError(SSORedirectError.SomethingWentWrong);
			},
		});
	}

	private handleRedirectWithError(redirectError: SSORedirectError) {
		const params = new URLSearchParams({
			redirectError,
		});

		window.location.replace(`${routePaths.login}?${params.toString()}`);
	}

	private authenticateUser(user: SSOUserData) {
		setUser(user);
		window.location.replace(routePaths.studentAssignments);
	}
}