import {API, GraphQLResult, GraphQLSubscription, GRAPHQL_AUTH_MODE} from '@aws-amplify/api';
import {Config} from './types';
import {GraphQLOptions} from '@aws-amplify/api-graphql';
import {concatMap, debounceTime, from, map, Observable, of, shareReplay, tap} from 'rxjs';
import Amplify from '@aws-amplify/core';
import {retry} from '@esgi/core/service';

type AdditionalHeaders = {
	[key: string]: string
};

class AmplifyManagerImpl {
	private config: Config;
	private lastToken: string;
	private initialize: Observable<void>;

	public get api() {
		if (!this.config) {
			console.error('AmplifyManager.api can\'t be called before AmplifyManager.init method');
			throw 'AmplifyManager not initialized';
		}
		return API;
	}

	public init(config: Config | Observable<Config>) {
		let initialize$: Observable<Config>;
		if ('tokenGenerator' in config) {
			initialize$ = of(config);
		} else {
			initialize$ = config;
		}
		this.initialize = initialize$.pipe(map(config => this.initializeWithConfig(config)), shareReplay(1));
	}

	public get identity() {
		return this.config.identityGenerator();
	}

	public appSyncSubscribe<T>(options: GraphQLOptions, additionalHeaders?: AdditionalHeaders): Observable<GraphQLResult<T>> {
		return this.initialize.pipe(
			concatMap(() => this.token()),
			concatMap(token => {
				return this.graphQlRequest<T>({
					query: options.query,
					variables: options.variables,
					authToken: token,
					authMode: 'AWS_LAMBDA',
				}, additionalHeaders);
			}),
			retry({
				count: 5,
				debounce: attempt => attempt * 1000,
				filter: (error) => {
					const err = error?.error?.errors[0];
					if (err) {
						if (err.errorCode !== 401 && err.message !== 'Connection failed: UnauthorizedException') {
							return false;
						}
						this.clearToken();
						return true;
					}
					return false;
				},
				resetOnSuccess: true,
			}),
		);
	}

	private graphQlRequest<T>(options: GraphQLOptions, additionalHeaders: AdditionalHeaders): Observable<GraphQLResult<T>> {
		return new Observable<GraphQLResult<T>>(subscriber => {
			const sub = this.api
				.graphql<GraphQLSubscription<T>>({
					...options,
					authMode: 'AWS_LAMBDA',
				}, additionalHeaders).subscribe({
					next: ({value}) => subscriber.next(value as any),
					error: (error) => subscriber.error(error),
					complete: () => subscriber.complete,
				});
			return () => sub.unsubscribe();
		});
	}

	private initializeWithConfig(config: Config) {
		if (this.config) {
			console.warn('AmplifyManager.init called more than one time. This method should be called only once');
		}

		Amplify.configure({
			'aws_appsync_graphqlEndpoint': config.graphQLUrl,
			'aws_appsync_region': 'us-east-1',
			'authMode': GRAPHQL_AUTH_MODE.AWS_LAMBDA,
		});

		this.config = config;
	}

	private clearToken() {
		return this.lastToken = null;
	}

	private token() {
		if (this.lastToken) {
			return of(this.lastToken);
		}
		const generator = this.config.tokenGenerator(this.lastToken === null);
		if(typeof generator === 'string') {
			this.lastToken = generator;
			return of(generator);
		}

		return from(generator).pipe(tap(t => this.lastToken = t));
	}
}

export const AmlifyManager = new AmplifyManagerImpl();