import {ElementStatus, useFormElementContext} from '@esgillc/ui-kit/form';
import {PropsWithChildren} from 'react';
import {InputError} from '../input-error';

type InputControlledErrorProps = PropsWithChildren<{
	showOnError: 'required' | 'length-min' | string;
}>;

export function InputControlledError({showOnError, children}: InputControlledErrorProps) {
	const context = useFormElementContext({}, InputControlledError);
	const error = context.firstError();

	const isShowInputError = error === showOnError && context.status !== ElementStatus.disabled;

	return isShowInputError ? <InputError>{children}</InputError> : null;
}
