import React from 'react';
import {FlexBox} from '@esgillc/ui-kit/layout';
import {environment} from '@ac/environments';
import styles from './styles.module.less';

export function SsoButtons() {
	return (
		<FlexBox direction='column' align='center' justify='center'>
			<hr data-content='OR' className={styles.hr}/>
			<a href={`${environment.ssoApiURL}/integrations/clever/student/auth-esgi`} tabIndex={5}>
				<img src='https://s3.amazonaws.com/esgiwebfiles/Images/Login-Clever-Button.png'/>
			</a>
			<br />
			<a href={`${environment.ssoApiURL}/integrations/class-link/student/auth-esgi`} tabIndex={6}>
				<img src='https://s3.amazonaws.com/esgiwebfiles/Images/login-classlink-button.png'
						 width='145px;'/>
			</a>
		</FlexBox>
	);
}