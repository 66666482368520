import {LoadScriptFailedEvent} from '@ac/utils';
import {EventBusManager} from '@esgillc/events';
import {Alert} from '@esgillc/ui-kit/modal';
import React, {useEffect, useRef, useState} from 'react';
import styles from './load-error-alert.module.less';
import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';

export function LoadErrorAlert() {
	const [isFailToLoadScript, setIsFailToLoadScript] = useState(false);

	const modalRef = useModal();
	const onModalClose = useCloseModal(modalRef, location.reload);

	const {current: eventBus} = useRef(new EventBusManager());

	useEffect(() => {
		eventBus.subscribe(LoadScriptFailedEvent, () => setIsFailToLoadScript(true));

		return () => {
			eventBus.destroy();
		};
	}, []);

	if (!isFailToLoadScript) {
		return null;
	}

	return (
		<Alert className={styles.loadErrorAlert} modalManagerRef={modalRef} unsafeDesiredZIndex={11000}>
			<Alert.Body>
				A new version of ESGI is available. We need to reload the page to apply the changes. Thanks.
			</Alert.Body>
			<Alert.Footer>
				<Buttons.Link onClick={onModalClose}>OK</Buttons.Link>
			</Alert.Footer>
		</Alert>
	);
}
