import {createBrowserRouter} from 'react-router-dom';
import {routePaths} from './routes';
import {Login} from '@ac/pages/login';
import {ByClever, ByClassLink} from '@ac/integrations';
import {StudentAssignments} from '@ac/pages/student-assignments';
import {Fallback} from '@ac/pages/home';

export const router = createBrowserRouter([
	{
		path: routePaths.login,
		element: <Login />,
	},
	{
		path: routePaths.loginByClever,
		element: <ByClever />,
	},
	{
		path: routePaths.loginByClasslink,
		element: <ByClassLink />,
	},
	{
		path: routePaths.studentAssignments,
		element: <StudentAssignments />,
	},
	{
		path: '*',
		element: <Fallback />,
	},
]);
