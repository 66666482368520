import {Service} from '@esgi/core/service';
import {StudentHttpClient} from './student-http-client';
import {environment} from '@ac/environments';
import {NotAuthorizedHandler} from '@ac/utils';

export class AssignmentCenterService extends Service<StudentHttpClient> {
	protected override createHttpClient(): StudentHttpClient {
		return new StudentHttpClient([new NotAuthorizedHandler()], environment);
	}
}
