import {ControlValue, FormControl, FormGroup} from '@esgillc/ui-kit/form';
import {LoginDistrictIdService} from './services/login-district-id-service';
import {LoginUserCredentialsService} from './services/login-user-credentials-service';

type LoginGenericForm<Keys extends string> = FormGroup<
	Record<Keys, FormControl<string>>,
	ControlValue<Record<Keys, FormControl<string>>>
>;

export enum LoginDistrictIdFieldsPatternValidate {
	OnlyNumbers = 'OnlyNumbers',
	ResponseValidate = 'ResponseValidate',
}

export enum LoginDistrictIdFields {
	DistrictId = 'DistrictId',
}

export type LoginDistrictIdForm = LoginGenericForm<LoginDistrictIdFields>;

export enum LoginUserCredentialsFields {
	UserName = 'UserName',
	Password = 'Password',
}

export type LoginUserCredentialsForm =
	LoginGenericForm<LoginUserCredentialsFields>;

export type LoginContextValue = {
	loginDistrictIdForm: LoginDistrictIdForm;
	loginUserCredentialsForm: LoginUserCredentialsForm;
	formClassNames: Record<'form' | 'formElement' | 'label' | 'success' | 'error', string | undefined>;
	loginDistrictIdService: LoginDistrictIdService;
	loginUserCredentialsService: LoginUserCredentialsService;
} | null;

export interface ValidateDistrictResponse {
	isSuccess: true;
	errors: [];
}