import {AssignmentCenterService, setUser} from '@ac/root';
import {routePaths} from '@ac/router/routes';
import {BehaviorSubject} from 'rxjs';

type LoginRequest = {
	password: string;
	userName: string;
	districtID: number;
};

enum ErrorType {
	IncorrectCredentials = 'incorrect_credentials',
}

type LoginResponse =
	| {
			isSuccess: true;
			errors: [];
			value: {
				districtID: number;
				primaryTeacherID: number;
				schoolID: number;
				selectedGlobalSchoolYearID: number;
				studentID: number;
			};
	  }
	| {
			isSuccess: false;
			value: null;
			errors: {
				args: null;
				description: string;
				type: ErrorType;
			}[];
	  };

export class LoginUserCredentialsService extends AssignmentCenterService {
	public isFormSubmitting$ = new BehaviorSubject(false);
	public responseError$ = new BehaviorSubject<string | null>(
		null
	);
	public isValidationEnable$ = new BehaviorSubject(false);

	private readonly controller = 'login';

	private setIsFormSubmitting(value: boolean) {
		this.isFormSubmitting$.next(value);
	}

	public clearResponseError() {
		this.responseError$.next(null);
	}

	public setIsValidationEnable(value: boolean) {
		this.isValidationEnable$.next(value);
	}

	public get isValidationEnable() {
		return this.isValidationEnable$.value;
	}

	public login(
		{userName, password, districtID}: LoginRequest,
		redirectCallback?: () => void
	) {
		this.setIsFormSubmitting(true);

		this.httpClient.studentApi.post<LoginResponse>(
			this.controller,
			'by-credentials',
			{
				userName,
				password,
				districtID,
			}
		).subscribe({
			next: ({isSuccess, errors, value}) => {
				if (isSuccess) {
					setUser(value);
					redirectCallback
						? redirectCallback()
						: window.location.replace(routePaths.studentAssignments);
					return;
				}

				const incorrectCredentialsError = errors.find(
					({type}) => type === ErrorType.IncorrectCredentials
				);

				if (incorrectCredentialsError) {
					const {description} = incorrectCredentialsError;

					this.responseError$.next(description);
				}

				this.setIsFormSubmitting(false);
			},
			error: () => {
				this.setIsFormSubmitting(false);
			},
		});
	}
}
