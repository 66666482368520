import {PropsWithChildren} from 'react';
import {QuestionMarkIcon} from '@esgillc/ui-kit/icons';
import styles from './styles.module.less';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';

type CardHeaderProps = PropsWithChildren<{
	titleNumber: number;
	infoText?: string;
}>;

export function CardHeader({titleNumber, children, infoText}: CardHeaderProps) {
	return (
		<div className={styles.title}>
			<span className={styles.number}>{titleNumber}</span>
			<div className={styles.textContainer}>
				{children}
				<div className={styles.buttonContainer}>
					{infoText && (
						<OnHoverTooltip message={infoText}>
							<div className={styles.infoContainer}>
								<QuestionMarkIcon fill='white' />
							</div>
						</OnHoverTooltip>
					)}
				</div>
			</div>
		</div>
	);
}
