import {useCallback} from 'react';
import styles from './styles.module.less';
import {isTouchDevice} from '@esgillc/ui-kit/utils';
import {ButtonCircled, IconPlay} from '@ac/kit';

type StartButtonProps = {
	onClick: () => void;
};

export function StartButton({onClick}: StartButtonProps) {
	const handleClick = useCallback(() => {
		setTimeout(() => onClick(), 300);
	}, [onClick]);

	const onTouchStart = useCallback(() => {
		if (isTouchDevice()) {
			onClick();
		}
	}, [onClick]);

	return (
		<ButtonCircled className={styles.start} color='success' onClick={handleClick} onTouchStart={onTouchStart}>
			<IconPlay />
		</ButtonCircled>
	);
}
