import {dispatchAppEvent} from '@esgillc/events';
import {registerDefaultErrorHandler} from '@esgillc/ui-kit/core';
import React, {useCallback, useEffect, useState} from 'react';
import * as ReactDOM from 'react-dom/client';
import {RouterProvider} from 'react-router-dom';
import axios from 'axios';
import {
	EnvironmentValue,
	environment,
	initEnvironmenValues,
} from '@ac/environments';
import {LoadErrorAlert, NewVersionAvailableAlert} from '@ac/kit';
import {router} from '@ac/router/router';

import './assets/styles/theme.less';
import './assets/styles/font-awesome.min.css';
import './assets/styles/fonts.css';
import './assets/styles/fonts.less';
import './assets/styles/bootstrap.min.css';

import './styles.less';

import {ReportErrorEvent} from './root/events';
import {StudentHttpClient, getUser} from '@ac/root';
import {map, tap} from 'rxjs';
import {AmlifyManager} from '@esgi/aws';
import {initLogRocket} from '@ac/utils/logrocket';

function App() {
	const [isEnvironmentLoaded, setIsEnvironmentLoaded] = useState(false);

	useEffect(() => {
		registerDefaultErrorHandler(() => {
			dispatchAppEvent(ReportErrorEvent, {
				message: 'Uh oh. Something went wrong with the UI on our end.',
			} as ReportErrorEvent);
		});

		initLogRocket();
		initEnvironment();
	}, []);

	const initEnvironment = useCallback(async () => {
		try {
			const {data} = await axios.get<EnvironmentValue>('/student/env');
			initEnvironmenValues(environment, data);

			setIsEnvironmentLoaded(true);

			const appSyncAuthenticateRequest = new StudentHttpClient([], environment)
				.studentApi
				.post<{
					token: string,
					graphQLUrl: string,
				}>('app-sync', 'authenticate').asObservable();

			const storageKey = 'ac_appsync_token';
			const tokenGenerator = async (force?: boolean) => {
				const storageToken = localStorage.getItem(storageKey);
				if (force || !storageToken) {
					const {token} = await appSyncAuthenticateRequest.toPromise();
					localStorage.setItem(storageKey, token);
					return token;
				}

				return storageToken;
			};

			AmlifyManager.init(appSyncAuthenticateRequest.pipe(
				tap((r) => localStorage.setItem(storageKey, r.token)),
				map(r => {
					return {
						tokenGenerator,
						graphQLUrl: r.graphQLUrl,
						identityGenerator: () => {
							const {studentID} = getUser();
							return {
								userID: studentID,
								userType: 'student',
							};
						},
					};
				})
			));
		} catch (error) {
			console.error(error);
		}
	}, []);

	if (!isEnvironmentLoaded) {
		return null;
	}

	return (
		<>
			<LoadErrorAlert/>
			<NewVersionAvailableAlert/>
			<RouterProvider router={router}/>
		</>
	);
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement, {
	identifierPrefix: 'assignment-center',
}).render(<App/>);
