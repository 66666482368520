import {PropsWithChildren} from 'react';
import styles from '../../styles/card.module.less';
import {join} from '@esgillc/ui-kit/utils';
import {CardTemplate} from '../card-template';

type CardProps = PropsWithChildren<{
	selected?: boolean;
	titleNumber: number;
	className?: string;
}>;

export function Card({selected = false, titleNumber, className, children}: CardProps) {
	return (
		<div className={join(styles.card, className, selected || styles.disabled)}>
			<div className={styles.content}>{children}</div>
			<CardTemplate number={titleNumber} />
		</div>
	);
}
