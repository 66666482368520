type ContentAreaIconProps = {
	contentAreaID: number;
};

export function ContentAreaIcon({contentAreaID}: ContentAreaIconProps) {
	switch (contentAreaID) {
		case 56:
			return <LanguageArtsIcon />;
		case 57:
			return <MathematicsIcon />;
		case 100:
			return <SpanishIcon />;
		case 101:
			return <ScienceIcon />;
		case 102:
			return <SocialStudiesIcon />;
		case 105:
			return <SELIcon />;
		default:
			return <OtherIcon />;
	}
}

function LanguageArtsIcon() {
	return (
		<svg width='153' height='149' viewBox='0 0 153 149' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M125.899 28.6127L124.486 30.0257H147.306V136.976H104.28C95.7779 137.001 87.3783 138.831 79.6369 142.345V74.8744L73.9849 80.5264V142.6C66.0718 138.894 57.4438 136.965 48.7062 136.948H5.65201V29.9832H52.3659C55.0125 30.19 57.6363 30.6249 60.2081 31.2832L60.3494 31.1278L64.772 26.7051C60.8474 25.442 56.7877 24.6453 52.6767 24.3312H0V142.586H48.7062C57.2967 142.606 65.7604 144.659 73.4056 148.577L74.0131 148.888H78.9728L79.5804 148.577C87.2261 144.66 95.6894 142.608 104.28 142.586H153V24.3312H128.456C127.975 25.9499 127.096 27.422 125.899 28.6127V28.6127Z'
				fill='#0088CC'
			/>
			<path d='M60.8581 111.57H17.1115V117.222H60.8581V111.57Z' fill='#0088CC' />
			<path
				d='M17.1115 74.5777H31.7642C32.9794 72.6937 34.2511 70.8098 35.5794 68.9258H17.1115V74.5777Z'
				fill='#0088CC'
			/>
			<path d='M17.1115 53.2555H49.0453L54.6973 47.6036H17.1115V53.2555Z' fill='#0088CC' />
			<path d='M135.889 111.57H92.142V117.222H135.889V111.57Z' fill='#0088CC' />
			<path d='M135.889 90.248H92.142V95.8999H135.889V90.248Z' fill='#0088CC' />
			<path d='M135.889 68.9258H92.142V74.5777H135.889V68.9258Z' fill='#0088CC' />
			<path d='M135.874 47.6036H106.908L101.256 53.2555H135.874V47.6036Z' fill='#0088CC' />
			<path
				d='M64.3339 35.1267C63.1786 36.2889 62.5301 37.8611 62.5301 39.4999C62.5301 41.1387 63.1786 42.7109 64.3339 43.8731L68.3328 39.8743C68.2384 39.7725 68.186 39.6387 68.186 39.4999C68.186 39.361 68.2384 39.2273 68.3328 39.1255L92.7212 14.8077C94.2455 13.273 96.2309 12.2803 98.3732 11.9817L51.7441 58.4977C45.843 64.3842 40.6286 70.9211 36.2011 77.9831L28.5002 90.3186L31.2555 93.0739C31.2555 93.0739 26.3524 99.7574 27.4546 100.86C28.0056 101.411 35.2544 97.0727 35.2544 97.0727L38.0804 99.828L50.4017 92.1131C57.4637 87.6856 64.0006 82.4712 69.8871 76.5701L121.886 24.5715C122.614 23.8365 123.066 22.8718 123.164 21.8415C123.262 20.8111 123 19.7785 122.423 18.9195L126.859 14.4968C129.488 11.8545 128.668 7.34704 124.853 3.53193C121.038 -0.28318 116.53 -1.10277 113.902 1.53955L109.465 5.96225C108.597 5.41241 107.571 5.16683 106.547 5.26403C105.524 5.36123 104.562 5.7957 103.813 6.4992L103.531 6.79596C100.932 6.07176 98.1861 6.0595 95.5809 6.76052C92.9758 7.46153 90.6069 8.85005 88.7224 10.7806L64.3339 35.1267ZM120.854 7.53066C121.775 8.34284 122.434 9.41002 122.748 10.5969L118.509 14.836L113.549 9.8763L117.788 5.63729C118.982 5.94451 120.055 6.60429 120.868 7.53066H120.854ZM65.8741 72.6136C64.3057 74.182 62.6808 75.6798 61.0275 77.1352L51.2213 67.3431C52.6343 65.6758 54.1886 64.0508 55.7429 62.4965L106.922 11.3176L117.053 21.4347L65.8741 72.6136ZM38.8575 92.65L35.7206 89.499L41.0335 81.021C43.0557 77.8144 45.2442 74.7157 47.5899 71.7375L56.6613 80.8231C53.6913 83.1753 50.592 85.3595 47.3779 87.3653L38.8575 92.65Z'
				fill='#0088CC'
			/>
		</svg>
	);
}

function MathematicsIcon() {
	return (
		<svg width='153' height='176' viewBox='0 0 153 176' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M57.8782 146.717H147.9V23.2203H57.8782V146.717ZM141.737 29.3828V64.2379H63.9914V29.3828H141.737ZM63.9914 121.278H85.782V140.554H63.9914V121.278ZM113.784 89.6767H91.9938V70.4004H113.784V89.6767ZM113.784 115.116H91.9938V95.8392H113.784V115.116ZM119.947 89.6767V70.4004H141.737V89.6767H119.947ZM85.8313 70.4004V89.6767H64.0407V70.4004H85.8313ZM85.8313 95.8392V115.116H64.0407V95.8392H85.8313ZM91.9938 121.278H113.784V140.554H91.9938V121.278ZM119.947 95.8392H141.737V140.554H119.947V95.8392Z'
				fill='#0088CC'
			/>
			<path
				d='M39.7358 0H7.3457C3.3031 0 0 3.3031 0 7.3457V162C0 166.042 3.3031 169.345 7.3457 169.345H39.7358C43.7784 169.345 47.0815 166.042 47.0815 162V7.3457C47.0815 3.3031 43.7784 0 39.7358 0ZM6.1625 22.9245V19.2763V22.9245ZM6.1625 42.4473V38.8484H18.9805V35.7425V32.6859H6.1625V29.0377V42.4473ZM6.1625 52.258V48.6098V52.258ZM6.1625 71.8301V68.1819H18.9805V65.1253V62.0687H6.1625V58.4205V71.8301ZM6.1625 81.5915V77.9433V81.5915ZM6.1625 101.164V97.5154H18.9805V94.4588V91.4022H6.1625V87.754V101.164ZM6.1625 110.925V107.277V110.925ZM6.1625 130.497V126.849H18.9805V123.792V120.736H6.1625V117.087V130.497ZM6.1625 140.308V136.66V140.308ZM6.1625 13.1138V7.3457C6.1625 6.7048 6.7048 6.1625 7.3457 6.1625H39.7358C40.3767 6.1625 40.919 6.7048 40.919 7.3457V162C40.919 162.641 40.3767 163.183 39.7358 163.183H7.3457C6.7048 163.183 6.1625 162.641 6.1625 162V156.232H18.9805V153.175V150.118H6.1625V146.421'
				fill='#0088CC'
			/>
			<path
				d='M23.5654 12.5222C21.2976 12.5222 19.4242 14.3956 19.4242 16.6634C19.4242 18.9312 21.2976 20.8046 23.5654 20.8046C25.8332 20.8046 27.7066 18.9312 27.7066 16.6634C27.6573 14.3956 25.8332 12.5222 23.5654 12.5222Z'
				fill='#0088CC'
			/>
			<path
				d='M137.005 34.51H119.947V58.7656H137.005V34.51ZM130.842 52.6031H126.109V40.6725H130.842V52.6031Z'
				fill='#0088CC'
			/>
		</svg>
	);
}

function SpanishIcon() {
	return (
		<svg width='153' height='142' viewBox='0 0 153 142' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M70.2387 30.3407V9.47458C70.2424 8.42595 70.6605 7.42129 71.402 6.6798C72.1435 5.9383 73.1482 5.52013 74.1968 5.5165H143.512C144.559 5.52015 145.562 5.93875 146.301 6.68064C147.041 7.42253 147.456 8.42721 147.456 9.47458V78.7618C147.456 79.8091 147.041 80.8138 146.301 81.5557C145.562 82.2976 144.559 82.7162 143.512 82.7198H136.685V96.2077L114.619 82.7198H93.7666V88.2363H113.074L142.229 106.041V88.2501H143.539C146.05 88.2465 148.456 87.2467 150.23 85.4702C152.004 83.6938 153 81.286 153 78.7756V9.47458C153 6.96415 152.004 4.55631 150.23 2.77988C148.456 1.00345 146.05 0.00365419 143.539 0H74.1968C71.6851 0.00364807 69.2774 1.003 67.5013 2.77904C65.7253 4.55508 64.7259 6.96288 64.7222 9.47458V30.3407H70.2387Z'
				fill='#0088CC'
			/>
			<path
				d='M9.47457 124.107H10.7848V141.912L39.9256 124.107H78.7755C81.2872 124.104 83.695 123.104 85.471 121.328C87.2471 119.552 88.2465 117.144 88.2501 114.633V45.3318C88.2465 42.8201 87.2471 40.4123 85.471 38.6363C83.695 36.8602 81.2872 35.8609 78.7755 35.8572H9.47457C6.96288 35.8609 4.55508 36.8602 2.77904 38.6363C1.003 40.4123 0.00364807 42.8201 0 45.3318V114.619C-2.65566e-06 117.133 0.997766 119.544 2.7742 121.323C4.55063 123.102 6.96049 124.104 9.47457 124.107V124.107ZM5.5165 45.3318C5.52013 44.2832 5.9383 43.2785 6.67979 42.537C7.42129 41.7955 8.42594 41.3774 9.47457 41.3737H78.7755C79.8242 41.3774 80.8288 41.7955 81.5703 42.537C82.3118 43.2785 82.73 44.2832 82.7336 45.3318V114.619C82.73 115.668 82.3118 116.672 81.5703 117.414C80.8288 118.155 79.8242 118.573 78.7755 118.577H38.3672L16.3013 132.065V118.577H9.47457C8.42594 118.573 7.42129 118.155 6.67979 117.414C5.9383 116.672 5.52013 115.668 5.5165 114.619V45.3318Z'
				fill='#0088CC'
			/>
			<path
				d='M39.0706 89.4913H49.2071L52.4205 102.083L57.7577 100.704L46.8075 57.868H41.4703L30.52 100.704L35.8572 102.083L39.0706 89.4913ZM44.132 69.7009L47.828 83.9748H40.5324L44.132 69.7009Z'
				fill='#0088CC'
			/>
			<path
				d='M97.5412 19.7031V16.4394C97.5412 16.1894 97.4904 15.9726 97.3888 15.789C97.2912 15.6015 97.1427 15.457 96.9435 15.3554C96.7443 15.2538 96.4982 15.2031 96.2052 15.2031C95.9318 15.2031 95.6916 15.2499 95.4845 15.3437C95.2814 15.4374 95.1213 15.5605 95.0041 15.7128C94.8908 15.8652 94.8342 16.0292 94.8342 16.205H93.7502C93.7502 15.9785 93.8088 15.7538 93.9259 15.5312C94.0431 15.3085 94.2111 15.1074 94.4299 14.9277C94.6525 14.7441 94.9181 14.5995 95.2267 14.4941C95.5392 14.3847 95.8869 14.33 96.2697 14.33C96.7306 14.33 97.1369 14.4081 97.4884 14.5644C97.8439 14.7206 98.1213 14.957 98.3205 15.2734C98.5236 15.5859 98.6252 15.9785 98.6252 16.4511V19.4042C98.6252 19.6152 98.6427 19.8398 98.6779 20.0781C98.717 20.3163 98.7736 20.5214 98.8478 20.6933V20.787H97.717C97.6623 20.662 97.6193 20.496 97.5881 20.289C97.5568 20.0781 97.5412 19.8828 97.5412 19.7031ZM97.7287 16.9433L97.7404 17.705H96.6447C96.3361 17.705 96.0607 17.7304 95.8185 17.7812C95.5763 17.8281 95.3732 17.9003 95.2092 17.998C95.0451 18.0956 94.9201 18.2187 94.8342 18.3671C94.7482 18.5117 94.7052 18.6816 94.7052 18.8769C94.7052 19.0761 94.7502 19.2578 94.84 19.4218C94.9299 19.5859 95.0646 19.7167 95.2443 19.8144C95.4279 19.9081 95.6525 19.955 95.9181 19.955C96.2502 19.955 96.5431 19.8847 96.797 19.7441C97.0509 19.6035 97.2521 19.4316 97.4006 19.2285C97.5529 19.0253 97.6349 18.8281 97.6467 18.6367L98.1095 19.1581C98.0822 19.3222 98.008 19.5038 97.8869 19.7031C97.7658 19.9023 97.6037 20.0937 97.4006 20.2773C97.2013 20.457 96.9631 20.6074 96.6857 20.7285C96.4123 20.8456 96.1037 20.9042 95.7599 20.9042C95.3302 20.9042 94.9533 20.8203 94.6291 20.6523C94.3088 20.4843 94.0588 20.2597 93.8791 19.9785C93.7033 19.6933 93.6154 19.3749 93.6154 19.0234C93.6154 18.6835 93.6818 18.3847 93.8146 18.1269C93.9474 17.8652 94.1388 17.6484 94.3888 17.4765C94.6388 17.3007 94.9396 17.1679 95.2912 17.0781C95.6427 16.9882 96.0353 16.9433 96.4689 16.9433H97.7287ZM95.7892 13.5097L96.9259 11.787H98.2326L96.6623 13.5097H95.7892Z'
				fill='#0088CC'
			/>
		</svg>
	);
}

function ScienceIcon() {
	return (
		<svg width='153' height='229' viewBox='0 0 153 229' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path d='M90.7688 189.794H62.2748V198.035H90.7688V189.794Z' fill='#0088CC' />
			<path d='M90.7688 161.98H62.2748V170.221H90.7688V161.98Z' fill='#0088CC' />
			<path
				d='M75.7078 228.671H77.2531C94.3917 228.516 110.981 222.606 124.357 211.889C137.732 201.173 147.118 186.271 151.007 169.579C154.895 152.887 153.061 135.372 145.798 119.847C138.535 104.323 126.265 91.6898 110.959 83.9772V8.24115H119.365V0H33.8016V8.24115H42.0427V83.9772C26.7441 91.6855 14.4783 104.31 7.21358 119.824C-0.0511654 135.338 -1.89378 152.843 1.98151 169.53C5.85681 186.217 15.2253 201.118 28.5837 211.843C41.9422 222.567 58.516 228.494 75.646 228.671H75.7078ZM47.9557 90.3023L50.3457 89.2103V8.24115H102.718V89.2103L105.108 90.3023C117.916 96.2586 128.567 106.032 135.6 118.281H103.728C94.6508 118.288 85.7225 120.584 77.7681 124.956L71.3194 128.521C64.5814 132.217 57.0214 134.158 49.3361 134.166H10.8089C13.4575 124.602 18.1505 115.727 24.564 108.154C30.9775 100.58 38.9585 94.4898 47.9557 90.3023ZM9.0782 142.407H49.3773C58.4542 142.4 67.3826 140.104 75.3369 135.732L81.7856 132.188C88.5184 128.476 96.0807 126.527 103.769 126.522H139.659C143.905 136.818 145.556 148 144.468 159.084C143.38 170.168 139.586 180.815 133.419 190.089C127.252 199.364 118.901 206.981 109.1 212.271C99.2993 217.561 88.349 220.363 77.2118 220.43H75.9344C57.9738 220.208 40.8205 212.933 28.1773 200.174C15.5342 187.415 8.41528 170.196 8.35715 152.234C8.35855 148.945 8.59951 145.661 9.0782 142.407Z'
				fill='#0088CC'
			/>
		</svg>
	);
}

function SocialStudiesIcon() {
	return (
		<svg width='153' height='203' viewBox='0 0 153 203' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M64.0988 176.36V195.306H48.9248V202.355H86.1636V195.306H71.0777V176.36C90.2021 175.614 108.519 168.454 123.08 156.033C137.64 143.611 147.597 126.651 151.347 107.883C155.098 89.1155 152.423 69.6315 143.754 52.5683C135.086 35.5051 120.927 21.8555 103.558 13.817L108.176 2.69642L101.655 0L90.3051 27.3872C85.3827 25.6283 80.273 24.4455 75.0783 23.8625C72.6151 23.5811 70.138 23.4398 67.6588 23.4395C52.3035 23.4431 37.4058 28.6687 25.4128 38.2579C13.4197 47.8471 5.0444 61.2298 1.66217 76.208C-1.72007 91.1863 0.0919658 106.869 6.80071 120.682C13.5095 134.494 24.716 145.614 38.5798 152.216L27.2478 179.603L33.7509 182.299L38.3507 171.197C46.6122 174.248 55.2996 175.99 64.0988 176.36ZM7.35059 84.5585C8.64793 72.6183 13.4586 61.3314 21.1735 52.1265C28.8884 42.9216 39.1608 36.2124 50.6904 32.8483C62.2201 29.4842 74.4886 29.6163 85.9431 33.228C97.3977 36.8396 107.523 43.7684 115.038 53.1373C122.553 62.5063 127.119 73.8941 128.159 85.8595C129.199 97.8248 126.666 109.83 120.88 120.355C115.094 130.88 106.315 139.451 95.6557 144.985C84.996 150.518 72.9342 152.765 60.997 151.44C53.0775 150.585 45.4049 148.174 38.4191 144.347C31.4332 140.52 25.2716 135.351 20.2876 129.138C15.3036 122.924 11.5952 115.788 9.37521 108.138C7.15519 100.488 6.46714 92.4749 7.35059 84.5585ZM60.2217 158.455C62.6859 158.721 65.1625 158.857 67.6412 158.86C82.995 158.852 97.8898 153.624 109.88 144.033C121.87 134.443 130.242 121.06 133.622 106.083C137.002 91.1056 135.189 75.4242 128.48 61.6137C121.771 47.8033 110.565 36.6846 96.7025 30.0836L100.738 20.3378C116.858 27.8774 129.924 40.6885 137.781 56.6562C145.637 72.6238 147.813 90.7931 143.95 108.164C140.086 125.536 130.415 141.07 116.532 152.203C102.648 163.336 85.3838 169.402 67.5882 169.399C58.4993 169.415 49.4788 167.828 40.9413 164.711L44.9772 154.947C49.9118 156.703 55.0334 157.88 60.2393 158.455H60.2217Z'
				fill='#0088CC'
			/>
			<path
				d='M28.4638 116.845L30.2262 118.343C34.1915 134.645 40.6064 138.187 46.8452 140.408L50.7226 141.8L52.3614 133.165L55.287 129.64L61.2262 128.124L63.5174 120.687L67.8174 116.598L66.901 108.756L64.9977 107.311C58.218 102.379 51.9797 96.7434 46.3872 90.4977L45.8937 89.9337L45.224 89.6342C45.224 89.6342 41.4172 87.8718 40.3245 87.4312L37.5753 93.9167L41.6287 95.6791C47.2489 101.909 53.4908 107.548 60.257 112.51L60.3979 113.867L57.3314 116.792L55.5691 122.22L51.2864 123.313L45.6998 129.887L45.312 131.931C42.2278 129.992 39.1437 126.045 36.7469 115.541L36.5002 114.431L34.1034 112.333C32.6348 109.138 31.3519 105.86 30.2614 102.517C30.8958 101.178 31.8652 99.0805 32.4115 98.0054L32.7817 97.2828V96.4721C32.7817 95.5909 32.7816 94.5688 32.8874 93.5466V91.6256L31.3189 90.5329L30.0324 89.6342C28.1589 84.8404 27.5519 79.6448 28.27 74.5482C29.8033 57.6119 51.445 50.5096 59.2171 48.4828L54.1767 54.7569L55.9391 64.4675L52.2734 64.6261L41.2234 67.9923L31.8299 76.4164L36.5354 81.7035L44.7834 74.3192L53.4717 71.658L64.2574 71.1997L61.7374 56.7307L68.7868 47.9189L62.8477 40.4993L60.768 40.8694C59.27 41.1514 23.7583 47.9189 21.3263 73.8786C20.4553 80.2338 21.2696 86.7064 23.6878 92.6478L24.3047 94.1458L25.1329 94.745L25.7322 95.168C25.7322 95.2737 25.7322 95.3971 25.7322 95.5028C24.7805 97.4767 23.3177 100.631 23.2472 100.79L22.6656 102.041L23.3353 104.086C24.6096 108.077 26.1463 111.981 27.9351 115.77L28.4638 116.845Z'
				fill='#0088CC'
			/>
			<path
				d='M81.1411 62.5465L86.0052 64.0445L88.067 57.3123L86.4634 56.8188L83.432 49.0468C92.7297 52.7692 100.405 59.67 105.092 68.521L111.348 65.2958C105.33 53.9357 95.1593 45.3369 82.9564 41.2924L82.2161 41.0457L73.1046 41.9092L81.1411 62.5465Z'
				fill='#0088CC'
			/>
			<path
				d='M105.127 72.0985L97.8129 72.6977L90.7635 69.7722L78.7443 72.9092L70.373 85.3339L69.721 96.9479L79.7134 108.826L81.7578 108.403L88.8073 106.958C89.5382 108.626 90.1794 110.332 90.7282 112.069C89.6884 115.171 88.6311 117.691 88.6134 117.726L88.402 118.202L86.2694 138.187L91.962 135.438C105.902 128.759 116.635 109.003 117.516 88.4533V86.9729L110.167 79.2185L105.127 72.0985ZM94.905 125.111L95.4691 119.982C95.9097 118.907 96.9846 116.193 97.9539 113.126L98.2888 112.069L97.9539 111.012C96.9567 107.714 95.6956 104.502 94.1826 101.407L92.9664 98.8337L90.1996 99.4858C88.825 99.803 84.9125 100.596 82.4981 101.09L76.9995 94.5511L77.3872 87.6602L83.1852 79.0599L90.2347 77.1917L96.5794 79.8529L101.69 79.4476L104.739 83.5716L110.449 89.6341C109.586 103.804 103.311 117.62 94.905 125.111Z'
				fill='#0088CC'
			/>
		</svg>
	);
}

function SELIcon() {
	return (
		<svg width='153' height='156' viewBox='0 0 153 156' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M137.376 63.0047C136.667 56.6508 135.225 50.4003 133.078 44.378C129.254 34.2995 123.115 25.2609 115.155 17.9915C107.195 10.7222 97.6384 5.42569 87.2553 2.52924C76.8721 -0.367208 65.9535 -0.782414 55.3804 1.31706C44.8073 3.41654 34.8757 7.97192 26.3869 14.6157C17.8982 21.2595 11.0899 29.8056 6.51157 39.5646C1.93319 49.3236 -0.287122 60.0221 0.0297193 70.797C0.346561 81.5719 3.1917 92.1214 8.33561 101.594C13.4795 111.068 20.7781 119.199 29.6426 125.332V155.02H35.3739V122.381L32.9811 120.633C24.8961 115.001 18.2454 107.551 13.5638 98.8815C8.88212 90.2118 6.30014 80.5645 6.02546 70.7154C5.75079 60.8662 7.79105 51.0901 11.9822 42.173C16.1734 33.2559 22.3986 25.4467 30.1572 19.3733C37.9157 13.2999 46.9911 9.1318 56.6537 7.20419C66.3162 5.27658 76.2963 5.6432 85.7914 8.27461C95.2865 10.906 104.032 15.7288 111.324 22.3551C118.616 28.9814 124.251 37.2263 127.777 46.4269C129.786 52.1453 131.118 58.0794 131.746 64.1079V64.4948L132.075 65.2541C138.107 75.986 145.701 97.4067 146.862 103.382C147.058 104.287 147.178 105.207 147.22 106.133H131.932L131.788 108.841C131.244 118.383 128.737 128.04 126.501 136.68C126.34 137.291 125.982 137.832 125.482 138.218C124.982 138.604 124.368 138.814 123.736 138.815H96.6557V155.02H102.387V144.604H123.736C125.658 144.606 127.526 143.963 129.04 142.779C130.554 141.594 131.628 139.936 132.089 138.07C134.531 129.504 136.275 120.754 137.305 111.907H146.804C149.384 111.907 150.96 111.377 151.905 110.216C153.095 108.783 153.338 106.262 152.521 102.307C151.275 95.9882 143.724 74.5102 137.376 63.0047Z'
				fill='#0088CC'
			/>
			<path
				d='M29.4134 64.6522C29.4248 53.8863 33.7065 43.5646 41.3192 35.9519C48.9319 28.3392 59.2536 24.0575 70.0196 24.0461C78.9995 24.0575 87.6083 27.6297 93.9581 33.9795C100.308 40.3292 103.88 48.9381 103.892 57.918C103.884 65.3942 100.911 72.5621 95.6242 77.8486C90.3377 83.1351 83.1698 86.1083 75.6935 86.1159C69.4941 86.1083 63.5508 83.6423 59.1672 79.2587C54.7835 74.875 52.3175 68.9317 52.3099 62.7323C52.3137 57.6072 54.3513 52.693 57.9753 49.069C61.5994 45.445 66.5135 43.4073 71.6387 43.4035C81.2243 43.4035 85.5943 49.6793 85.5943 55.5252C85.6287 58.3451 84.5471 61.0644 82.5854 63.0905C81.3196 64.2726 79.8286 65.1875 78.2014 65.7808C76.5742 66.3741 74.8441 66.6335 73.1145 66.5436V72.2749C85.0642 72.2749 91.3256 63.8499 91.3256 55.5252C91.3256 46.656 84.5627 37.6722 71.6387 37.6722C64.9947 37.6798 58.6249 40.3225 53.9269 45.0205C49.2289 49.7186 46.5862 56.0883 46.5786 62.7323C46.59 70.4506 49.6611 77.8494 55.1187 83.3071C60.5764 88.7647 67.9753 91.8358 75.6935 91.8472C84.6898 91.8396 93.3155 88.2625 99.6768 81.9012C106.038 75.5399 109.615 66.9142 109.623 57.918C109.611 47.418 105.435 37.3514 98.0107 29.9269C90.5862 22.5023 80.5195 18.3262 70.0196 18.3148C57.7336 18.3262 45.9541 23.2118 37.2666 31.8993C28.5791 40.5868 23.6935 52.3663 23.6821 64.6522C23.6821 94.7415 46.4067 117.509 76.5389 117.509V111.778C49.6162 111.778 29.4134 91.5177 29.4134 64.6522Z'
				fill='#0088CC'
			/>
		</svg>
	);
}

function OtherIcon() {
	return (
		<svg width='154' height='142' viewBox='0 0 154 142' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M124.182 113.895C123.663 113.895 123.166 113.688 122.799 113.322C122.433 112.955 122.227 112.458 122.227 111.939V106.562C122.227 106.044 122.433 105.546 122.799 105.18C123.166 104.813 123.663 104.607 124.182 104.607C124.701 104.607 125.198 104.813 125.565 105.18C125.931 105.546 126.137 106.044 126.137 106.562V111.939C126.137 112.458 125.931 112.955 125.565 113.322C125.198 113.688 124.701 113.895 124.182 113.895Z'
				fill='#0088CC'
			/>
			<path
				d='M30.3292 113.895C29.8106 113.895 29.3133 113.688 28.9466 113.322C28.58 112.955 28.3738 112.458 28.3738 111.939V106.562C28.3738 106.044 28.58 105.546 28.9466 105.18C29.3133 104.813 29.8106 104.607 30.3292 104.607C30.8478 104.607 31.3451 104.813 31.7118 105.18C32.0784 105.546 32.2843 106.044 32.2843 106.562V111.939C32.2843 112.458 32.0784 112.955 31.7118 113.322C31.3451 113.688 30.8478 113.895 30.3292 113.895Z'
				fill='#0088CC'
			/>
			<path
				d='M134.936 113.895C133.958 113.895 133.47 113.406 133.47 111.939V106.562C133.47 105.585 133.958 104.607 134.936 104.607C135.455 104.607 135.952 104.813 136.319 105.18C136.685 105.546 136.891 106.044 136.891 106.562V111.939C136.891 113.406 136.402 113.895 134.936 113.895Z'
				fill='#0088CC'
			/>
			<path
				d='M19.5751 113.895C18.1087 113.895 17.6197 113.406 17.6197 111.939V106.562C17.6197 106.044 17.8259 105.546 18.1926 105.18C18.5593 104.813 19.0566 104.607 19.5751 104.607C20.5528 104.607 21.0415 105.585 21.0415 106.562V111.939C21.0415 113.406 20.5528 113.895 19.5751 113.895Z'
				fill='#0088CC'
			/>
			<path
				d='M124.182 130.514C123.663 130.514 123.166 130.308 122.799 129.942C122.433 129.575 122.227 129.078 122.227 128.559V123.182C122.227 122.664 122.433 122.166 122.799 121.8C123.166 121.433 123.663 121.227 124.182 121.227C124.701 121.227 125.198 121.433 125.565 121.8C125.931 122.166 126.137 122.664 126.137 123.182V128.559C126.137 129.078 125.931 129.575 125.565 129.942C125.198 130.308 124.701 130.514 124.182 130.514Z'
				fill='#0088CC'
			/>
			<path
				d='M30.3292 130.514C29.8106 130.514 29.3133 130.308 28.9466 129.942C28.58 129.575 28.3738 129.078 28.3738 128.559V123.182C28.3738 122.664 28.58 122.166 28.9466 121.8C29.3133 121.433 29.8106 121.227 30.3292 121.227C30.8478 121.227 31.3451 121.433 31.7118 121.8C32.0784 122.166 32.2843 122.664 32.2843 123.182V128.559C32.2843 129.078 32.0784 129.575 31.7118 129.942C31.3451 130.308 30.8478 130.514 30.3292 130.514Z'
				fill='#0088CC'
			/>
			<path
				d='M134.936 130.514C133.958 130.514 133.47 129.537 133.47 128.559V123.182C133.47 122.205 133.958 121.227 134.936 121.227C135.455 121.227 135.952 121.433 136.319 121.8C136.685 122.166 136.891 122.664 136.891 123.182V128.559C136.891 129.078 136.685 129.575 136.319 129.942C135.952 130.308 135.455 130.514 134.936 130.514Z'
				fill='#0088CC'
			/>
			<path
				d='M19.5751 130.514C19.0566 130.514 18.5593 130.308 18.1926 129.942C17.8259 129.575 17.6197 129.078 17.6197 128.559V123.182C17.6197 122.664 17.8259 122.166 18.1926 121.8C18.5593 121.433 19.0566 121.227 19.5751 121.227C20.5528 121.227 21.0415 122.205 21.0415 123.182V128.559C21.0415 129.537 20.5528 130.514 19.5751 130.514Z'
				fill='#0088CC'
			/>
			<path
				d='M77.2556 25.9073C76.737 25.9073 76.2397 25.7013 75.873 25.3346C75.5064 24.9679 75.3002 24.4706 75.3002 23.952V1.95524C75.3002 1.43667 75.5064 0.939369 75.873 0.572684C76.2397 0.206 76.737 0 77.2556 0H96.3196C96.8382 0 97.3355 0.206 97.7022 0.572684C98.0689 0.939369 98.2747 1.43667 98.2747 1.95524V12.7091C98.2747 13.2277 98.0689 13.7252 97.7022 14.0919C97.3355 14.4585 96.8382 14.6645 96.3196 14.6645H79.211V23.952C79.211 24.4706 79.0048 24.9679 78.6381 25.3346C78.2715 25.7013 77.7742 25.9073 77.2556 25.9073ZM79.211 10.7539H94.8529V3.91048H79.211V10.7539Z'
				fill='#0088CC'
			/>
			<path
				d='M112.939 141.757H102.185C101.928 141.757 101.674 141.706 101.437 141.608C101.2 141.51 100.984 141.366 100.803 141.184C100.621 141.003 100.477 140.787 100.379 140.55C100.281 140.313 100.23 140.058 100.23 139.802C100.23 139.545 100.281 139.291 100.379 139.053C100.477 138.816 100.621 138.601 100.803 138.419C100.984 138.238 101.2 138.093 101.437 137.995C101.674 137.897 101.928 137.847 102.185 137.847H110.984V52.7922C110.984 52.2736 111.19 51.7763 111.557 51.4096C111.923 51.0429 112.421 50.8369 112.939 50.8369C114.406 50.8369 114.895 51.3257 114.895 52.7922V139.802C114.895 140.32 114.688 140.818 114.322 141.184C113.955 141.551 113.458 141.757 112.939 141.757Z'
				fill='#0088CC'
			/>
			<path
				d='M52.326 141.757H41.5719C41.0534 141.757 40.5561 141.551 40.1894 141.184C39.8227 140.818 39.6166 140.32 39.6166 139.802V52.7922C39.6166 51.3257 40.1055 50.8369 41.5719 50.8369C42.0905 50.8369 42.5878 51.0429 42.9545 51.4096C43.3212 51.7763 43.527 52.2736 43.527 52.7922V137.847H52.326C52.8446 137.847 53.3419 138.052 53.7086 138.419C54.0752 138.786 54.2811 139.283 54.2811 139.802C54.2811 140.32 54.0752 140.818 53.7086 141.184C53.3419 141.551 52.8446 141.757 52.326 141.757Z'
				fill='#0088CC'
			/>
			<path
				d='M118.805 59.147H117.339L77.2556 26.8851L37.1724 59.147C36.7187 59.4711 36.1549 59.6017 35.6049 59.51C35.0549 59.4184 34.5637 59.112 34.2396 58.6583C33.9155 58.2045 33.7849 57.6406 33.8765 57.0906C33.9682 56.5405 34.2746 56.0494 34.7283 55.7253L76.2779 22.9745C76.5467 22.734 76.8948 22.6011 77.2556 22.6011C77.6163 22.6011 77.9644 22.734 78.2333 22.9745L119.783 55.7253C120.175 56.0924 120.436 56.5787 120.524 57.1089C120.613 57.6391 120.524 58.1837 120.271 58.6583L118.805 59.147Z'
				fill='#0088CC'
			/>
			<path
				d='M77.2557 69.901C74.6249 69.8992 72.0548 69.1112 69.8751 67.6382C67.6953 66.1652 66.0053 64.0744 65.0224 61.6341C64.0394 59.1939 63.8084 56.5156 64.3586 53.943C64.9087 51.3703 66.215 49.0209 68.11 47.1961C70.005 45.3712 72.402 44.1545 74.9936 43.7018C77.5851 43.2491 80.2529 43.5812 82.6543 44.6554C85.0558 45.7297 87.0812 47.4971 88.471 49.7308C89.8607 51.9646 90.5512 54.5627 90.4538 57.1917C90.3272 60.6066 88.881 63.8394 86.4195 66.2098C83.958 68.5802 80.6729 69.9033 77.2557 69.901ZM77.2557 47.9042C75.4188 47.9042 73.623 48.449 72.0957 49.4695C70.5684 50.49 69.3781 51.9405 68.6751 53.6376C67.9721 55.3347 67.7884 57.202 68.1467 59.0036C68.5051 60.8052 69.3895 62.4601 70.6884 63.759C71.9873 65.0579 73.6422 65.9424 75.4438 66.3008C77.2454 66.6592 79.1128 66.4752 80.8099 65.7723C82.507 65.0693 83.9574 63.879 84.9779 62.3517C85.9984 60.8243 86.5433 59.0286 86.5433 57.1917C86.5433 54.7285 85.5647 52.3662 83.823 50.6244C82.0812 48.8827 79.7189 47.9042 77.2557 47.9042Z'
				fill='#0088CC'
			/>
			<path
				d='M102.185 97.2746H52.326C51.3484 97.2746 50.3706 96.7858 50.3706 95.8082V79.1885C50.3706 78.67 50.5768 78.1727 50.9434 77.806C51.3101 77.4393 51.8074 77.2332 52.326 77.2332H102.185C102.704 77.2332 103.201 77.4393 103.568 77.806C103.934 78.1727 104.141 78.67 104.141 79.1885V95.8082C104.141 96.7858 103.163 97.2746 102.185 97.2746ZM54.2811 93.8531H100.23V81.1436H54.2811V93.8531Z'
				fill='#0088CC'
			/>
			<path
				d='M85.5656 97.2746C84.5879 97.2746 83.6102 96.7858 83.6102 95.8082V79.1885C83.6102 78.67 83.8163 78.1727 84.183 77.806C84.5497 77.4393 85.047 77.2332 85.5656 77.2332C86.0841 77.2332 86.5814 77.4393 86.9481 77.806C87.3148 78.1727 87.5207 78.67 87.5207 79.1885V95.8082C87.5207 96.7858 86.5432 97.2746 85.5656 97.2746Z'
				fill='#0088CC'
			/>
			<path
				d='M68.9456 97.2746C67.968 97.2746 66.9905 96.7858 66.9905 95.8082V79.1885C66.9905 78.67 67.1964 78.1727 67.5631 77.806C67.9298 77.4393 68.4271 77.2332 68.9456 77.2332C69.4642 77.2332 69.9615 77.4393 70.3282 77.806C70.6949 78.1727 70.901 78.67 70.901 79.1885V95.8082C70.901 96.7858 69.9233 97.2746 68.9456 97.2746Z'
				fill='#0088CC'
			/>
			<path
				d='M102.185 141.757H52.326C51.8074 141.757 51.3101 141.551 50.9434 141.184C50.5768 140.818 50.3706 140.32 50.3706 139.802V111.939C50.3706 111.421 50.5768 110.923 50.9434 110.557C51.3101 110.19 51.8074 109.984 52.326 109.984H102.185C102.704 109.984 103.201 110.19 103.568 110.557C103.934 110.923 104.141 111.421 104.141 111.939V139.802C104.141 140.32 103.934 140.818 103.568 141.184C103.201 141.551 102.704 141.757 102.185 141.757ZM54.2811 137.847H100.23V113.895H54.2811V137.847Z'
				fill='#0088CC'
			/>
			<path
				d='M85.5656 141.757C85.047 141.757 84.5497 141.551 84.183 141.184C83.8163 140.818 83.6102 140.32 83.6102 139.802V111.939C83.6102 111.421 83.8163 110.923 84.183 110.557C84.5497 110.19 85.047 109.984 85.5656 109.984C86.0841 109.984 86.5814 110.19 86.9481 110.557C87.3148 110.923 87.5207 111.421 87.5207 111.939V139.802C87.5207 140.32 87.3148 140.818 86.9481 141.184C86.5814 141.551 86.0841 141.757 85.5656 141.757Z'
				fill='#0088CC'
			/>
			<path
				d='M68.9456 141.757C68.4271 141.757 67.9298 141.551 67.5631 141.184C67.1964 140.818 66.9905 140.32 66.9905 139.802V111.939C66.9905 111.421 67.1964 110.923 67.5631 110.557C67.9298 110.19 68.4271 109.984 68.9456 109.984C69.4642 109.984 69.9615 110.19 70.3282 110.557C70.6949 110.923 70.901 111.421 70.901 111.939V139.802C70.901 140.32 70.6949 140.818 70.3282 141.184C69.9615 141.551 69.4642 141.757 68.9456 141.757Z'
				fill='#0088CC'
			/>
			<path
				d='M151.556 97.2746H124.182C123.204 97.2746 122.227 96.7858 122.227 95.8082C122.227 95.2896 122.433 94.7923 122.799 94.4256C123.166 94.0589 123.663 93.8531 124.182 93.8531H148.134L139.824 81.1436H124.182C123.663 81.1436 123.166 80.9378 122.799 80.5711C122.433 80.2044 122.227 79.7071 122.227 79.1885C122.227 78.67 122.433 78.1727 122.799 77.806C123.166 77.4393 123.663 77.2332 124.182 77.2332H140.802C141.291 77.2332 141.779 77.2332 142.268 78.2108L153.511 94.3418C153.511 95.3194 154 95.8084 153.511 96.2972C153.022 96.786 152.534 97.2746 151.556 97.2746Z'
				fill='#0088CC'
			/>
			<path
				d='M30.3292 97.2746H2.95526C1.97763 97.2746 1.48899 97.2748 1.00017 96.2972C0.511354 95.3195 1.00017 95.3194 1.00017 94.3418L12.2429 78.2108C12.7317 77.2332 13.2205 77.2332 13.7093 77.2332H30.3292C30.8478 77.2332 31.3451 77.4393 31.7118 77.806C32.0785 78.1727 32.2843 78.67 32.2843 79.1885C32.2843 79.7071 32.0785 80.2044 31.7118 80.5711C31.3451 80.9378 30.8478 81.1436 30.3292 81.1436H14.687L6.37705 93.8531H30.3292C30.8478 93.8531 31.3451 94.0589 31.7118 94.4256C32.0785 94.7923 32.2843 95.2896 32.2843 95.8082C32.2843 96.7858 31.3069 97.2746 30.3292 97.2746Z'
				fill='#0088CC'
			/>
			<path
				d='M146.179 141.757H124.182C123.925 141.757 123.671 141.707 123.434 141.608C123.197 141.51 122.981 141.366 122.799 141.184C122.618 141.003 122.474 140.787 122.376 140.55C122.278 140.313 122.227 140.058 122.227 139.802C122.227 139.545 122.278 139.291 122.376 139.053C122.474 138.816 122.618 138.601 122.799 138.419C122.981 138.238 123.197 138.093 123.434 137.995C123.671 137.897 123.925 137.847 124.182 137.847H144.224V95.8081C144.224 95.2895 144.43 94.7922 144.796 94.4256C145.163 94.0589 145.66 93.853 146.179 93.853C146.697 93.853 147.195 94.0589 147.561 94.4256C147.928 94.7922 148.134 95.2895 148.134 95.8081V139.802C148.134 140.32 147.928 140.818 147.561 141.184C147.195 141.551 146.697 141.757 146.179 141.757Z'
				fill='#0088CC'
			/>
			<path
				d='M30.3292 141.757H8.33239C7.81382 141.757 7.31652 141.551 6.94983 141.184C6.58315 140.818 6.377 140.32 6.377 139.802V95.8081C6.377 95.2895 6.58315 94.7922 6.94983 94.4256C7.31652 94.0589 7.81382 93.853 8.33239 93.853C8.85096 93.853 9.34826 94.0589 9.71495 94.4256C10.0816 94.7922 10.2875 95.2895 10.2875 95.8081V137.847H30.3292C30.8478 137.847 31.3451 138.052 31.7118 138.419C32.0784 138.786 32.2843 139.283 32.2843 139.802C32.2843 140.32 32.0784 140.818 31.7118 141.184C31.3451 141.551 30.8478 141.757 30.3292 141.757Z'
				fill='#0088CC'
			/>
		</svg>
	);
}
