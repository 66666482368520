import {Modal, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Loader} from '@esgillc/ui-kit/loader';
import {CloseButton} from '../close-button';
import {NameBar} from '@ac/kit';
import styles from './styles.module.less';

interface Props {
	studentName: string;
	showLoader?: boolean;
	closed: () => void;
}

export function FinishTestSessionModal({studentName, showLoader = false, closed}: Props) {
	const modalRef = useModal();
	const modalClose = useCloseModal(modalRef, closed);

	return (
		<Modal containerClassName={styles.modalWrapper} className={styles.modal} modalManagerRef={modalRef}>
			<Loader show={showLoader} />
			<Modal.Header className={styles.modalHeader}>
				<NameBar title={studentName} className={styles.starredNameContainer} />
				<CloseButton closed={modalClose} />
			</Modal.Header>
			<Modal.Body className={styles.modalBody}>
				<div className={styles.intro}>
					<span>Success! You finished!</span>
					<div className={styles.snakeContainer}>
						<img className={styles.snake} alt='Snake' src='//s3.amazonaws.com/esgiwebfiles/images/snake-new.png' />
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
}
