import {join} from '@esgillc/ui-kit/utils';
import {IconStar} from '../icon';
import styles from './name-bar.module.less';

type NameBarProps = {
	title: string;
	className?: string | undefined;
};

export function NameBar({className, title}: NameBarProps) {
	return (
		<div className={join(styles.nameBarContainer, className)}>
			<div className={styles.title}>
				<div>
					<IconStar />
					<h2>{title}</h2>
					<IconStar />
				</div>
			</div>
		</div>
	);
}
