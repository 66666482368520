import {useCallback, useId} from 'react';
import {LoginUserCredentialsFields, LoginUserCredentialsForm} from '../types';
import {LoginUserCredentialsService} from '../services/login-user-credentials-service';
import {FormControl, FormGroup, Validators, useForm} from '@esgillc/ui-kit/form';
import {useNavigate} from 'react-router-dom';
import {routePaths} from '@ac/router/routes';
import {emptyString, passwordMinLength, usernameMinLength} from '../constants';

export function useUserCredentialsForm(service: LoginUserCredentialsService) {
	const formID = useId();
	const navigate = useNavigate();

	const goToStudentAssignmentsPage = useCallback(() => {
		navigate(routePaths.studentAssignments, {replace: true});
	}, [navigate]);

	const form: LoginUserCredentialsForm = useForm(
		() =>
			new FormGroup({
				[LoginUserCredentialsFields.UserName]: new FormControl(emptyString, {
					validators: [Validators.required(), Validators.length.min(usernameMinLength)],
				}),
				[LoginUserCredentialsFields.Password]: new FormControl(emptyString, {
					validators: [Validators.required(), Validators.length.min(passwordMinLength)],
				}),
			}),
	);

	const onFormSubmit = useCallback(
		(districtID: number) => {
			service.clearResponseError();

			form.validate().subscribe((result) => {
				if (!service.isValidationEnable) {
					service.setIsValidationEnable(true);
				}

				if (result.valid) {
					const userName = form.value[LoginUserCredentialsFields.UserName];
					const password = form.value[LoginUserCredentialsFields.Password];

					service.login(
						{
							districtID,
							userName,
							password,
						},
						goToStudentAssignmentsPage,
					);
				}
			});
		},
		[form, service, goToStudentAssignmentsPage],
	);

	return {
		formID,
		form,
		onFormSubmit,
	};
}
