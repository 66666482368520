import {useMemo} from 'react';
import {TestsProgress} from '../../../types';

export function useGetTestsInfo(tests: TestsProgress[]) {
	return useMemo(() => {
		const activeTests: TestsProgress[] = [];
		const passedTests: TestsProgress[] = [];

		tests.forEach((test) => {
			test.isCompleted ? passedTests.push(test) : activeTests.push(test);
		});

		return {
			allTestsCount: tests.length,
			passedTestsCount: passedTests.length,
		};
	}, [tests]);
}
