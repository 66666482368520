import {
	MouseEvent,
	PropsWithChildren,
	TouchEvent,
	forwardRef,
	useCallback,
} from 'react';
import {join} from '@esgillc/ui-kit/utils';
import styles from './button-circled.module.less';

type ButtonCircledProps = PropsWithChildren<{
	className?: string | undefined;
	onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
	color?: 'success';
	disabled?: boolean;
	type?: 'button' | 'reset' | 'submit';
	form?: string;
	onTouchStart?: (event: TouchEvent<HTMLButtonElement>) => void;
}>;

export const ButtonCircled = forwardRef<HTMLButtonElement, ButtonCircledProps>(
	(
		{
			onClick,
			className,
			children,
			color,
			disabled,
			type = 'button',
			form,
			onTouchStart,
		},
		ref
	) => {
		const onButtonClick = useCallback(
			(event: MouseEvent<HTMLButtonElement>) => {
				if (!disabled) {
					onClick?.(event);
				}
			},
			[onClick, disabled]
		);

		return (
			<button
				onClick={onButtonClick}
				className={join(
					styles.button,
					styles[`color-${color}`],
					disabled && styles.disabled,
					className
				)}
				type={type}
				ref={ref}
				disabled={disabled}
				form={form}
				onTouchStart={onTouchStart}
			>
				{children}
			</button>
		);
	}
);
