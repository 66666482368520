import {Environment} from '@ac/environments';
import {ErrorHandler, RequestBuilder, BaseHttpClient, BuilderOptions} from '@esgi/api';

export class StudentHttpClient extends BaseHttpClient {
	constructor(private defaultErrorHandlers: ErrorHandler[], private environment: Environment) {
		super();
	}

	public get studentApi(): RequestBuilder {
		return this.innerRequest(this.environment.studentApiURL);
	}

	protected override innerRequest(baseUrl: string, options?: Partial<Omit<BuilderOptions, 'baseUrl'>>): RequestBuilder {
		return super.innerRequest(baseUrl, {
			defaultErrorHandlers: this.defaultErrorHandlers,
			responseMapper: (data) => {
				if (data instanceof Blob) {
					return data;
				}
				if (data) {
					return (data as any).object;
				}
				return undefined;
			},
			...options,
		});
	}
}
