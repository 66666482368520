export enum SSORedirectError {
	UserNotLinked = 'UserNotLinked',
	SomethingWentWrong = 'SomethingWentWrong',
}

export interface SSOUserData {
	districtID: number;
	primaryTeacherID: number;
	schoolID: number;
	selectedGlobalSchoolYearID: number;
	studentID: number;
}

export interface SSOError {
	args: null;
	description: string;
	type: string;
}

export interface SSOLoginResponse {
	isSuccess: boolean;
	value: SSOUserData | null;
	errors: SSOError[];
}