import {useCallback, useId} from 'react';
import {LoginDistrictIdService} from '../services/login-district-id-service';
import {LoginDistrictIdFields, LoginDistrictIdFieldsPatternValidate, LoginDistrictIdForm} from '../types';
import {FormControl, FormGroup, Validators, useForm} from '@esgillc/ui-kit/form';
import {emptyString, onlyNumbersRegex} from '../constants';

type UseDistrictIdFormSubmit = {
	loginDistrictIdService: LoginDistrictIdService;
};

export function useDistrictIdForm({loginDistrictIdService}: UseDistrictIdFormSubmit) {
	const loginDistrictIdFormId = useId();

	const loginDistrictIdForm: LoginDistrictIdForm = useForm(
		() =>
			new FormGroup({
				[LoginDistrictIdFields.DistrictId]: new FormControl(emptyString, {
					validators: [
						Validators.required(),
						Validators.pattern(onlyNumbersRegex, LoginDistrictIdFieldsPatternValidate.OnlyNumbers),
					],
				}),
			}),
	);

	const onDistrictIdFormSubmit = useCallback(() => {
		loginDistrictIdService.clearDistrictIdFormResponseError();

		loginDistrictIdForm.validate().subscribe((result) => {
			if (!loginDistrictIdService.isFormDistrictIdValidationEnable) {
				loginDistrictIdService.setIsFormDistrictIdValidationEnable(true);
			}

			if (result.valid) {
				const districtId = loginDistrictIdForm.value[LoginDistrictIdFields.DistrictId];

				loginDistrictIdService.checkDistrictId(districtId);
			}
		});
	}, [loginDistrictIdForm, loginDistrictIdService]);

	return {
		loginDistrictIdFormId,
		loginDistrictIdForm,
		onDistrictIdFormSubmit,
	};
}
