/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const subscribe = /* GraphQL */ `
  subscription Subscribe($name: String!) {
    subscribe(name: $name) {
      data
      name
      __typename
    }
  }
`;
