import {FrontendNewVersionAvailableEvent} from '@ac/utils';
import {EventBusManager} from '@esgillc/events';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from './new-version-available-alert.module.less';
import {Buttons} from '@esgillc/ui-kit/button';

export function NewVersionAvailableAlert() {
	const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);

	const {current: eventBus} = useRef(new EventBusManager());

	useEffect(() => {
		eventBus.subscribe(FrontendNewVersionAvailableEvent, () => setIsNewVersionAvailable(true));

		return () => {
			eventBus.destroy();
		};
	}, []);

	const handleCloseAlert = useCallback(() => {
		setIsNewVersionAvailable(false);
	}, []);

	const handleReloadPage = useCallback(() => {
		location.reload();
	}, []);

	if (!isNewVersionAvailable) {
		return null;
	}

	return (
		<div className={styles.alertHolder}>
			<div className={styles.alert}>
				<div className={styles.text}>
					<h4>An ESGI update is available.</h4>
					<span>Update now or automatically refresh later.</span>
				</div>
				<div className={styles.actions}>
					<Buttons.Link onClick={handleCloseAlert}>LATER</Buttons.Link>
					<Buttons.Link onClick={handleReloadPage}>UPDATE NOW</Buttons.Link>
				</div>
			</div>
		</div>
	);
}
