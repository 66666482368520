import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {SSORedirectError, ssoRedirectErrorMessage} from '@ac/integrations';
import {FlexBox} from '@esgi/ui/layout';
import {InputError} from '../input-error';
import styles from './styles.module.less';

export function SsoRedirectError() {
	const [params] = useSearchParams();
	const [redirectError, setRedirectError] = useState<string>(null);

	useEffect(() => {
		const redirectError = params.get('redirectError') as SSORedirectError;
		if (!redirectError) {
			return;
		}

		setRedirectError(ssoRedirectErrorMessage[redirectError]);
	}, [params]);


	if (!redirectError) {
		return null;
	}

	return (
		<FlexBox className={styles.errorWrapper} justify='center'>
			<InputError>
				{redirectError}
			</InputError>
		</FlexBox>
	);
}