import {join} from '@esgillc/ui-kit/utils';
import styles from './text.module.less';
import {PropsWithChildren, createElement} from 'react';

type HTMLTextTagName = keyof Pick<JSX.IntrinsicElements, 'p' | 'span' | 'h2' | 'h3'>;

type TextProps<T extends HTMLTextTagName> = PropsWithChildren<{
	color?: 'primary' | 'secondary' | 'white' | 'black' | 'currentColor';
	size?: '14px' | '16px';
	weight?: '400' | '500';
	as: T;
	className?: string | undefined;
	maxLines?: '2';
}>;

export function Text<T extends HTMLTextTagName = HTMLTextTagName>({
	size,
	color = 'black',
	children,
	as,
	weight,
	className,
	maxLines,
}: TextProps<T>) {
	return createElement(
		as,
		{
			className: join(
				styles[`color-${color}`],
				styles[`size-${size}`],
				styles.text,
				weight && styles[`weight-${weight}`],
				maxLines && styles[`max-lines-${maxLines}`],
				maxLines && styles['max-lines'],
				className,
			),
		},
		children,
	);
}
