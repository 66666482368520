import {Modal, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {useCallback, useEffect, useState} from 'react';
import styles from './styles.module.less';
import {Buttons} from '@esgillc/ui-kit/button';
import {WithButtonsModal} from './components/with-buttons-modal';
import {debounceTime, fromEvent} from 'rxjs';
import {isTouchDevice} from '@esgillc/ui-kit/utils';

type LockScreenProps = {
	closeParent: () => void;
};

export function LockScreen({closeParent}: LockScreenProps) {
	const [showScreenLockOpened, setShowScreenLockOpened] = useState(false);
	const [showScreenLockWitButtonsOpened, setShowScreenLockWitButtonsOpened] = useState(false);

	const modalRef = useModal();
	const closeModal = useCloseModal(modalRef, () => setShowScreenLockOpened(false));

	useEffect(() => {
		const resize$ = fromEvent(window, 'resize')
			.pipe(debounceTime(200))
			.subscribe((event) => {
				handleResize(event.target as Window);
			});

		return () => resize$.unsubscribe();
	}, []);

	const handleResize = useCallback(
		(screenWindow: Window) => {
			if (screenWindow.innerHeight < 580 || screenWindow.innerWidth < 600) {
				if (!showScreenLockWitButtonsOpened) {
					setShowScreenLockWitButtonsOpened(true);
				}

				return;
			}

			if (screenWindow.innerHeight > screenWindow.innerWidth && isTouchDevice()) {
				if (!showScreenLockOpened) {
					setShowScreenLockOpened(true);
				}

				return;
			}

			if (showScreenLockOpened) {
				setShowScreenLockOpened(false);
			}
		},
		[showScreenLockOpened, showScreenLockWitButtonsOpened],
	);

	const onModalClose = useCallback(() => {
		closeParent();
		closeModal();
	}, [closeParent, closeModal]);

	const onWithButtonsModalClose = useCallback(() => {
		setShowScreenLockWitButtonsOpened(false);
	}, []);

	return (
		<>
			{showScreenLockOpened && (
				<Modal modalManagerRef={modalRef}>
					<Modal.Body className={styles.baseModal}>
						<span>Self-Assessment is only supported in horizontal viewing. Please re-orient your device.</span>
					</Modal.Body>
					<Modal.Footer>
						<Buttons.Text onClick={onModalClose} className={styles.button}>
							CLOSE
						</Buttons.Text>
					</Modal.Footer>
				</Modal>
			)}
			{showScreenLockWitButtonsOpened && (
				<WithButtonsModal onClickClose={onWithButtonsModalClose} closeParent={closeParent} />
			)}
		</>
	);
}
